// service
export const FETCH_SERVICES_INIT = "service@FETCH_SERVICES_INIT"
export const FETCH_SERVICES_SUCCEDED = "service@FETCH_SERVICES_SUCCEDED"
export const FETCH_SERVICES_FAILED = "service@FETCH_SERVICES_FAILED"

export const CLEAR_SERVICE = "service@CLEAR_SERVICE"

export const CREATE_SERVICE_INIT = "service@CREATE_SERVICE_INIT"
export const CREATE_SERVICE_SUCCEDED = "service@CREATE_SERVICE_SUCCEDED"
export const CREATE_SERVICE_FAILED = "service@CREATE_SERVICE_FAILED"

export const FETCH_SERVICE_INIT = "service@FETCH_SERVICE_INIT"
export const FETCH_SERVICE_SUCCEDED = "service@FETCH_SERVICE_SUCCEDED"
export const FETCH_SERVICE_FAILED = "service@FETCH_SERVICE_FAILED"

export const EDIT_SERVICE_INIT = "service@EDIT_SERVICE_INIT"
export const EDIT_SERVICE_SUCCEDED = "service@EDIT_SERVICE_SUCCEDED"
export const EDIT_SERVICE_FAILED = "service@EDIT_SERVICE_FAILED"

export const DEACTIVATE_SERVICE_INIT = "service@DEACTIVATE_SERVICE_INIT"
export const DEACTIVATE_SERVICE_SUCCEDED = "service@DEACTIVATE_SERVICE_SUCCEDED"
export const DEACTIVATE_SERVICE_FAILED = "service@DEACTIVATE_SERVICE_FAILED"

export const ACTIVATE_SERVICE_INIT = "service@ACTIVATE_SERVICE_INIT"
export const ACTIVATE_SERVICE_SUCCEDED = "service@ACTIVATE_SERVICE_SUCCEDED"
export const ACTIVATE_SERVICE_FAILED = "service@ACTIVATE_SERVICE_FAILED"


export const DELETE_SERVICE_INIT = "service@DELETE_SERVICE_INIT"
export const DELETE_SERVICE_SUCCEDED = "service@DELETE_SERVICE_SUCCEDED"
export const DELETE_SERVICE_FAILED = "service@DELETE_SERVICE_FAILED"
