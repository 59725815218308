export const HAS_INTALA9_COURSE = { 
  0: "GENERAL.NO",
  1:"GENERAL.OUI", 
}

export const hasIntala9CourseUIHelper = (intl) =>
  Object.keys(HAS_INTALA9_COURSE).map((key) => ({
    value: parseInt(key),
    label: intl.formatMessage({ id: HAS_INTALA9_COURSE[key] }),
  }))
