// knowledgeSkill
export const FETCH_KNOWLEDGE_SKILLS_INIT = "knowledgeSkill@FETCH_KNOWLEDGE_SKILLS_INIT"
export const FETCH_KNOWLEDGE_SKILLS_SUCCEDED = "knowledgeSkill@FETCH_KNOWLEDGE_SKILLS_SUCCEDED"
export const FETCH_KNOWLEDGE_SKILLS_FAILED = "knowledgeSkill@FETCH_KNOWLEDGE_SKILLS_FAILED"

export const CLEAR_KNOWLEDGE_SKILL = "knowledgeSkill@CLEAR_KNOWLEDGE_SKILL"

export const CREATE_KNOWLEDGE_SKILL_INIT = "knowledgeSkill@CREATE_KNOWLEDGE_SKILL_INIT"
export const CREATE_KNOWLEDGE_SKILL_SUCCEDED = "knowledgeSkill@CREATE_KNOWLEDGE_SKILL_SUCCEDED"
export const CREATE_KNOWLEDGE_SKILL_FAILED = "knowledgeSkill@CREATE_KNOWLEDGE_SKILL_FAILED"

export const FETCH_KNOWLEDGE_SKILL_INIT = "knowledgeSkill@FETCH_KNOWLEDGE_SKILL_INIT"
export const FETCH_KNOWLEDGE_SKILL_SUCCEDED = "knowledgeSkill@FETCH_KNOWLEDGE_SKILL_SUCCEDED"
export const FETCH_KNOWLEDGE_SKILL_FAILED = "knowledgeSkill@FETCH_KNOWLEDGE_SKILL_FAILED"

export const EDIT_KNOWLEDGE_SKILL_INIT = "knowledgeSkill@EDIT_KNOWLEDGE_SKILL_INIT"
export const EDIT_KNOWLEDGE_SKILL_SUCCEDED = "knowledgeSkill@EDIT_KNOWLEDGE_SKILL_SUCCEDED"
export const EDIT_KNOWLEDGE_SKILL_FAILED = "knowledgeSkill@EDIT_KNOWLEDGE_SKILL_FAILED"

export const DEACTIVATE_KNOWLEDGE_SKILL_INIT = "knowledgeSkill@DEACTIVATE_KNOWLEDGE_SKILL_INIT"
export const DEACTIVATE_KNOWLEDGE_SKILL_SUCCEDED = "knowledgeSkill@DEACTIVATE_KNOWLEDGE_SKILL_SUCCEDED"
export const DEACTIVATE_KNOWLEDGE_SKILL_FAILED = "knowledgeSkill@DEACTIVATE_KNOWLEDGE_SKILL_FAILED"

export const ACTIVATE_KNOWLEDGE_SKILL_INIT = "knowledgeSkill@ACTIVATE_KNOWLEDGE_SKILL_INIT"
export const ACTIVATE_KNOWLEDGE_SKILL_SUCCEDED = "knowledgeSkill@ACTIVATE_KNOWLEDGE_SKILL_SUCCEDED"
export const ACTIVATE_KNOWLEDGE_SKILL_FAILED = "knowledgeSkill@ACTIVATE_KNOWLEDGE_SKILL_FAILED"
