import _ from "lodash"

import { HTTP_METHODS } from "./../../../../../constants"
import { makeExternalCall } from "./../../../../../helpers"
import { isRLTLang } from "../../../../../i18n"


const FETCH_OPTIONS_ENDPOINT = "/api/pa/options/list?list_number=1"

const formatOptions = (options) => options.map((option) => (
  { label: isRLTLang() ? option.valueAr : option.valueFr, value: option.rank }))


export const residenceTypeUIHelper = (callback, keyword) => {

  let endpoint = FETCH_OPTIONS_ENDPOINT
  let query = {}
  if (!_.isEmpty(keyword)) {
    query = { q: keyword }
  }

  return new Promise((resolve, reject) =>
    makeExternalCall(HTTP_METHODS.GET, endpoint, {}, {}, query)
      .then(resp => {
        let result = {}
        if (_.isPlainObject(resp.data)) {
          result = [resp.data]
        } else {
          result = resp.data
        }
        resolve(callback(formatOptions(result)))
      })
      .catch(err => reject(err.response))
  )
}



/* export const RESIDENCE_TYPE = {
  1: "FOLDER.RESIDENCE_TYPE.RELATIVES",
  2: "FOLDER.RESIDENCE_TYPE.EXTENDED_FAMILY",
  3: "FOLDER.RESIDENCE_TYPE.FRIENDS",
  4: "FOLDER.RESIDENCE_TYPE.PERSONAL_HOME",
  5: "FOLDER.RESIDENCE_TYPE.SPECIALIZED_INSTITUTION"
}


export const residenceTypeUIHelper = (intl) =>
  Object.keys(RESIDENCE_TYPE).map((key) => ({
    value: parseInt(key),
    label: intl.formatMessage({ id: RESIDENCE_TYPE[key] }),
  }))
 */