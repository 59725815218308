import { ACTIONS } from "./../constants"


const initialState = { 
  transferRequests: [], 
  transferRequest: {}, 
  totalSize: 0,
  isFetching: false, 
  isLoading: false,
  hasMore: true, 
  success: {
    isDeleted: false,
    isCreated: false,
    isAccepted: false,
    isRefused: false,
  },
  error: null
}


export default (state = initialState , action) => {
  const { payload, type } = action
  switch (type) {

    case ACTIONS.CLEAR_FOLDER : {
      return { ...state, success: initialState.success, error: null, isFetching: false, isLoading: false }
    }

    case ACTIONS.FETCH_TRANSFER_REQUESTS_INIT : {
      return { ...state, isFetching: true, error: null }
    }
    case ACTIONS.FETCH_TRANSFER_REQUESTS_SUCCEDED : {
      const { count, results } = payload
      return { ...state, totalSize: count, transferRequests: results, isFetching: false, error: null }
    }
    case ACTIONS.FETCH_TRANSFER_REQUESTS_FAILED : {
      return { ...state, isFetching: false, error: payload }
    }

    case ACTIONS.CREATE_TRANSFER_REQUEST_INIT : {
      return { ...state, isLoading: true, error: null, success: initialState.success }
    }
    case ACTIONS.CREATE_TRANSFER_REQUEST_SUCCEDED : {
      return { ...state, success: { ...state.success, isCreated: true }, isLoading: false, error: null }
    }
    case ACTIONS.CREATE_TRANSFER_REQUEST_FAILED : {
      return { ...state, error: payload, isLoading: false }
    }

    case ACTIONS.EDIT_TRANSFER_REQUEST_INIT : {      
      return { ...state, isLoading: true, error: null, success: initialState.success }
    }
    case ACTIONS.EDIT_TRANSFER_REQUEST_SUCCEDED : {
      return { ...state, success: { ...state.success, isUpdated: true }, isLoading: false, error: null }
    }
    case ACTIONS.EDIT_TRANSFER_REQUEST_FAILED : {
      return { ...state, error: payload, isLoading: false }
    }

    case ACTIONS.DELETE_TRANSFER_REQUEST_INIT : {
      return { ...state, isLoading: true, error: null, success: initialState.success }
    }
    case ACTIONS.DELETE_TRANSFER_REQUEST_SUCCEDED : {
      return { ...state, success: { ...state.success, isDeleted: true }, isLoading: false, error: null }
    }
    case ACTIONS.DELETE_TRANSFER_REQUEST_FAILED : {
      return { ...state, error: payload, isLoading: false }
    }

    case ACTIONS.ACCEPT_TRANSFER_REQUEST_INIT : {
      return { ...state, isLoading: true, error: null, success: initialState.success }
    }
    case ACTIONS.ACCEPT_TRANSFER_REQUEST_SUCCEDED : {
      return { ...state, success: { ...state.success, isAccepted: true }, isLoading: false, error: null }
    }
    case ACTIONS.ACCEPT_TRANSFER_REQUEST_FAILED : {
      return { ...state, error: payload, isLoading: false }
    }

    case ACTIONS.REFUSE_TRANSFER_REQUEST_INIT : {
      return { ...state, isLoading: true, error: null, success: initialState.success }
    }
    case ACTIONS.REFUSE_TRANSFER_REQUEST_SUCCEDED : {
      return { ...state, success: { ...state.success, isRefused: true }, isLoading: false, error: null }
    }
    case ACTIONS.REFUSE_TRANSFER_REQUEST_FAILED : {
      return { ...state, error: payload, isLoading: false }
    }
 
    case ACTIONS.FETCH_TRANSFER_REQUEST_INIT : {
      return { ...state, isLoading: true, transferRequest: null, error: null }
    }
    case ACTIONS.FETCH_TRANSFER_REQUEST_SUCCEDED : {
      return { ...state, transferRequest: payload, isLoading: false, error: null }
    }
    case ACTIONS.FETCH_TRANSFER_REQUEST_FAILED : {
      return { ...state, isLoading: false, error: payload }
    }

    default: {
      return state
    }
  }
}
