
export const PASSED_COURSE = 1;
export const FAILED_COURSE = 2;
export const ABANDONMENT = 3;

export const EXIT_COURSE_D2C = {
[PASSED_COURSE]: "FOLDER.STATUS.PASSED_COURSE",
[FAILED_COURSE]: "FOLDER.STATUS.FAILED_COURSE",
[ABANDONMENT]: "FOLDER.STATUS.ABANDONMENT",
};

export const EXIT_COURSE_COLOR_D2C = {
[PASSED_COURSE]: "success",
[FAILED_COURSE]: "danger",
[ABANDONMENT]: "info",
};

export const exitD2CTypeUIHelper = (intl) =>
  Object.keys(EXIT_COURSE_D2C).map((key) => ({
    value: parseInt(key),
    label: intl.formatMessage({ id: EXIT_COURSE_D2C[key] }),
  }))
