import { ERROR_CODES, VALIDATION_ERROR_CODES } from '../constants'
import { isArray, isEmpty, isPlainObject } from 'lodash'
import { convertToSnakeCase } from './ObjectHelpers'

export class ForbiddenError extends Error {
}

export class PermissionDeniedError extends ForbiddenError {
  constructor(message) {
    super(message)
    this.name = 'PermissionDenied'
  }
}

export class AuthenticationError extends ForbiddenError {
  constructor(message) {
    super(message)
    this.name = 'AuthenticationError'
  }
}

export const formatFormError = (fields, data) => {
  let errors = {}

  if (!isPlainObject(data)) {
    return;
  }

  Object.keys(data).forEach((fieldName) => {
    if (isArray(data[fieldName])) {
      data[fieldName].forEach((obj) => {
        if (isPlainObject(obj) && Object.keys(obj).every(key => !isArray(obj[key]))) {
          errors = Object.assign(errors, getErrorMsg(fields, fieldName, obj.code, obj.message))
        } else if (isPlainObject(errors) && fieldName in errors) {
          errors[fieldName].push(formatFormError(obj))
        } else {
          errors[fieldName] = [formatFormError(obj)]
        }
      })
    } else if (isPlainObject(data[fieldName]) && isEmpty(data[fieldName])) {
      errors[fieldName] = [formatFormError(data[fieldName])]
    }
  })

  return errors
}

const getErrorMsg = (fields, fieldName, code, message) => {
  const errors = {}
  if (fieldName === 'nonFieldErrors') {
    fields.forEach((field) => {
      if (message.includes(convertToSnakeCase(field.name))) {
        errors[field.name] = VALIDATION_ERROR_CODES[code] || 'ERROR.CODE.VALIDATION.INVALID'
      }
    })
  } else {
    errors[fieldName] = VALIDATION_ERROR_CODES[code] || 'ERROR.CODE.VALIDATION.INVALID'
  }
  return errors
}

export const formatErrorMessage = (error) => {
  const { status, data = {} } = error
  const { code = undefined } = data
  if (status === 400 && !code) {
    return 'ERROR.CODE.FORM_FAILED'
  }
  return ERROR_CODES[(code || status)] || 'ERROR.CODE.INTERNAL_ERROR'
}
