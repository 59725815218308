export const SKILLS = "/api/skills"
export const SKILL = "/api/skills/:param"
export const SKILL_DEACTIVATE = "/api/skills/:param"
export const SKILL_ACTIVATE = "/api/skills/:param/activate"
export const EXPORT_SKILLS = "/api/skills/export"
export const IMPORT_SKILLS = "/api/skills/import"

// request skill
export const REQUEST_SKILLS = "/api/request-skills"
export const REQUEST_SKILL = "/api/request-skills/:param"
export const SKILL_CANCEL = "/api/request-skills/:param/cancel"