import _ from "lodash"

import { HTTP_METHODS } from "../../../../constants"
import { makeCall } from "../../../../helpers"
import {isRLTLang} from "../../../../i18n"
import { store } from "../../../../configureStore"


const FETCH_GROUP_APPOINTMENTS_ENDPOINT = "/api/folder-groups/:param/activity-reports/all?date=:date"


const formatAppointments = (appointments) => appointments.map((appointment) => 
  ({ 
    label: isRLTLang() ? appointment.titleAr : appointment.titleFr , 
    value: appointment.id 
  }) 
)


export const foderGroupActivityUIHelper = (callback,param,date) => {

  if (!_.isInteger(param) || !param || !date){
    callback([])
    return
  }

  let endpoint = FETCH_GROUP_APPOINTMENTS_ENDPOINT.replace(":param", param).replace(":date", date)
  const { token } = store.getState().common.auth || {}

  let query = {}

  return new Promise((resolve, reject) =>
    makeCall(HTTP_METHODS.GET, endpoint, {}, {'Authorization': `Bearer  ${token.access}`}, query)
    .then(resp => {
      let result = {}
      if (_.isPlainObject(resp.data)){
        result = [resp.data]
      }else {
        result = resp.data
      }
      resolve(callback(formatAppointments( result )))
    })
    .catch(err => reject(err.response))
  )
}
