// aptitudeSkill
export const FETCH_APTITUDE_SKILLS_INIT = "aptitudeSkill@FETCH_APTITUDE_SKILLS_INIT"
export const FETCH_APTITUDE_SKILLS_SUCCEDED = "aptitudeSkill@FETCH_APTITUDE_SKILLS_SUCCEDED"
export const FETCH_APTITUDE_SKILLS_FAILED = "aptitudeSkill@FETCH_APTITUDE_SKILLS_FAILED"

export const CLEAR_APTITUDE_SKILL = "aptitudeSkill@CLEAR_APTITUDE_SKILL"

export const CREATE_APTITUDE_SKILL_INIT = "aptitudeSkill@CREATE_APTITUDE_SKILL_INIT"
export const CREATE_APTITUDE_SKILL_SUCCEDED = "aptitudeSkill@CREATE_APTITUDE_SKILL_SUCCEDED"
export const CREATE_APTITUDE_SKILL_FAILED = "aptitudeSkill@CREATE_APTITUDE_SKILL_FAILED"

export const FETCH_APTITUDE_SKILL_INIT = "aptitudeSkill@FETCH_APTITUDE_SKILL_INIT"
export const FETCH_APTITUDE_SKILL_SUCCEDED = "aptitudeSkill@FETCH_APTITUDE_SKILL_SUCCEDED"
export const FETCH_APTITUDE_SKILL_FAILED = "aptitudeSkill@FETCH_APTITUDE_SKILL_FAILED"

export const EDIT_APTITUDE_SKILL_INIT = "aptitudeSkill@EDIT_APTITUDE_SKILL_INIT"
export const EDIT_APTITUDE_SKILL_SUCCEDED = "aptitudeSkill@EDIT_APTITUDE_SKILL_SUCCEDED"
export const EDIT_APTITUDE_SKILL_FAILED = "aptitudeSkill@EDIT_APTITUDE_SKILL_FAILED"

export const DEACTIVATE_APTITUDE_SKILL_INIT = "aptitudeSkill@DEACTIVATE_APTITUDE_SKILL_INIT"
export const DEACTIVATE_APTITUDE_SKILL_SUCCEDED = "aptitudeSkill@DEACTIVATE_APTITUDE_SKILL_SUCCEDED"
export const DEACTIVATE_APTITUDE_SKILL_FAILED = "aptitudeSkill@DEACTIVATE_APTITUDE_SKILL_FAILED"

export const ACTIVATE_APTITUDE_SKILL_INIT = "aptitudeSkill@ACTIVATE_APTITUDE_SKILL_INIT"
export const ACTIVATE_APTITUDE_SKILL_SUCCEDED = "aptitudeSkill@ACTIVATE_APTITUDE_SKILL_SUCCEDED"
export const ACTIVATE_APTITUDE_SKILL_FAILED = "aptitudeSkill@ACTIVATE_APTITUDE_SKILL_FAILED"
