// activityReport
export const FETCH_ACTIVITY_REPORTS_INIT = "activityReport@FETCH_ACTIVITY_REPORTS_INIT"
export const FETCH_ACTIVITY_REPORTS_SUCCEDED = "activityReport@FETCH_ACTIVITY_REPORTS_SUCCEDED"
export const FETCH_ACTIVITY_REPORTS_FAILED = "activityReport@FETCH_ACTIVITY_REPORTS_FAILED"

export const CLEAR_ACTIVITY_REPORT = "activityReport@CLEAR_ACTIVITY_REPORT"

export const CREATE_ACTIVITY_REPORT_INIT = "activityReport@CREATE_ACTIVITY_REPORT_INIT"
export const CREATE_ACTIVITY_REPORT_SUCCEDED = "activityReport@CREATE_ACTIVITY_REPORT_SUCCEDED"
export const CREATE_ACTIVITY_REPORT_FAILED = "activityReport@CREATE_ACTIVITY_REPORT_FAILED"

export const FETCH_ACTIVITY_REPORT_INIT = "activityReport@FETCH_ACTIVITY_REPORT_INIT"
export const FETCH_ACTIVITY_REPORT_SUCCEDED = "activityReport@FETCH_ACTIVITY_REPORT_SUCCEDED"
export const FETCH_ACTIVITY_REPORT_FAILED = "activityReport@FETCH_ACTIVITY_REPORT_FAILED"

export const EDIT_ACTIVITY_REPORT_INIT = "activityReport@EDIT_ACTIVITY_REPORT_INIT"
export const EDIT_ACTIVITY_REPORT_SUCCEDED = "activityReport@EDIT_ACTIVITY_REPORT_SUCCEDED"
export const EDIT_ACTIVITY_REPORT_FAILED = "activityReport@EDIT_ACTIVITY_REPORT_FAILED"

export const DEACTIVATE_ACTIVITY_REPORT_INIT = "activityReport@DEACTIVATE_ACTIVITY_REPORT_INIT"
export const DEACTIVATE_ACTIVITY_REPORT_SUCCEDED = "activityReport@DEACTIVATE_ACTIVITY_REPORT_SUCCEDED"
export const DEACTIVATE_ACTIVITY_REPORT_FAILED = "activityReport@DEACTIVATE_ACTIVITY_REPORT_FAILED"

export const ACTIVATE_ACTIVITY_REPORT_INIT = "activityReport@ACTIVATE_ACTIVITY_REPORT_INIT"
export const ACTIVATE_ACTIVITY_REPORT_SUCCEDED = "activityReport@ACTIVATE_ACTIVITY_REPORT_SUCCEDED"
export const ACTIVATE_ACTIVITY_REPORT_FAILED = "activityReport@ACTIVATE_ACTIVITY_REPORT_FAILED"

export const DELETE_ACTIVITY_REPORT_INIT = "activityReport@DELETE_ACTIVITY_REPORT_INIT"
export const DELETE_ACTIVITY_REPORT_SUCCEDED = "activityReport@DELETE_ACTIVITY_REPORT_SUCCEDED"
export const DELETE_ACTIVITY_REPORT_FAILED = "activityReport@DELETE_ACTIVITY_REPORT_FAILED"