import { ACTIONS } from "./../constants"

const initialState = {
  folders: [],
  folder: {},
  folderContent: {},
  folderExtraData: {},
  totalSize: 0,
  refresh: false,
  isFetching: false,
  isLoading: false,
  hasMore: true,
  success: {
    isDeactivated: false,
    isActivated: false,
    isCreated: false,
    isUpdated: false,
    isCanceledTransfer: false,
  },
  error: null,
}


export default (state = initialState, action) => {
  const { payload, type } = action
  switch (type) {
    case ACTIONS.CLEAR_FOLDER: {
      return {
        ...state,
        success: initialState.success,
        error: null,
        isFetching: false,
        isLoading: false,
      }
    }

    case ACTIONS.FETCH_FOLDERS_INIT: {
      return { ...state, isFetching: true, error: null }
    }
    case ACTIONS.FETCH_FOLDERS_SUCCEDED: {
      const { count, results } = payload
      return {
        ...state,
        totalSize: count,
        folders: results,
        isFetching: false,
        error: null,
      }
    }
    case ACTIONS.FETCH_FOLDERS_FAILED: {
      return { ...state, isFetching: false, error: payload }
    }

    case ACTIONS.FETCH_FOLDER_CONTENT_INIT: {
      return { ...state, isFetching: true, folderContent: null, error: null }
    }
    case ACTIONS.FETCH_FOLDER_CONTENT_SUCCEDED: {
      return { ...state, folderContent: payload, isFetching: false, error: null }
    }
    case ACTIONS.FETCH_FOLDER_CONTENT_FAILED: {
      return { ...state, isFetching: false, error: payload }
    }

    case ACTIONS.FETCH_FOLDER_INIT: {
      return { ...state, isFetching: true, refresh: false, error: null }
    }
    case ACTIONS.FETCH_FOLDER_SUCCEDED: {
      return { ...state, folder: payload, isLoading: false, isFetching: false, error: null }
    }
    case ACTIONS.FETCH_FOLDER_FAILED: {
      return { ...state, isFetching: false, isLoading: false, error: payload }
    }
    
    case ACTIONS.FETCH_FOLDER_EXTRA_DATA_INIT: {
      return { ...state, isFetching: true, folderExtraData: null, error: null }
    }
    case ACTIONS.FETCH_FOLDER_EXTRA_DATA_SUCCEDED: {
      return { ...state, folderExtraData: payload, isLoading: false, isFetching: false, error: null }
    }
    case ACTIONS.FETCH_FOLDER_EXTRA_DATA_FAILED: {
      return { ...state, isFetching: false, isLoading: false, error: payload }
    }

    default: {
      return state
    }
  }
}
