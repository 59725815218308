export const ABSENCE_SHEET_COMPLETED = 1
export const ABSENCE_SHEET_NOT_COMPLETED = 0

export const ABSENCE_SHEET_STATUS = {
  [ABSENCE_SHEET_COMPLETED]: "ABSENCE_SHEET.STATUS.COMPLETED",
  [ABSENCE_SHEET_NOT_COMPLETED]: "ABSENCE_SHEET.STATUS.NOT_COMPLETED",
}

export const ABSENCE_SHEET_STATUS_COLOR = {
  [ABSENCE_SHEET_COMPLETED]: "success",
  [ABSENCE_SHEET_NOT_COMPLETED]: "primary",
}

export const absenceSheetStatusUIHelper = (intl) =>
  Object.keys(ABSENCE_SHEET_STATUS).map((key) => ({
    value: parseInt(key),
    label: intl.formatMessage({ id: ABSENCE_SHEET_STATUS[key] }),
  }))
