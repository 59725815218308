// integrationSession
export const FETCH_INTEGRATION_SESSIONS_INIT = "integrationSession@FETCH_INTEGRATION_SESSIONS_INIT"
export const FETCH_INTEGRATION_SESSIONS_SUCCEDED = "integrationSession@FETCH_INTEGRATION_SESSIONS_SUCCEDED"
export const FETCH_INTEGRATION_SESSIONS_FAILED = "integrationSession@FETCH_INTEGRATION_SESSIONS_FAILED"

export const CLEAR_INTEGRATION_SESSION = "integrationSession@CLEAR_INTEGRATION_SESSION"
export const SET_APPOINTMENT_INIT_VALUE = "integrationSession@SET_APPOINTMENT_INIT_VALUE"

export const CREATE_INTEGRATION_SESSION_INIT = "integrationSession@CREATE_INTEGRATION_SESSION_INIT"
export const CREATE_INTEGRATION_SESSION_SUCCEDED = "integrationSession@CREATE_INTEGRATION_SESSION_SUCCEDED"
export const CREATE_INTEGRATION_SESSION_FAILED = "integrationSession@CREATE_INTEGRATION_SESSION_FAILED"

export const FETCH_INTEGRATION_SESSION_INIT = "integrationSession@FETCH_INTEGRATION_SESSION_INIT"
export const FETCH_INTEGRATION_SESSION_SUCCEDED = "integrationSession@FETCH_INTEGRATION_SESSION_SUCCEDED"
export const FETCH_INTEGRATION_SESSION_FAILED = "integrationSession@FETCH_INTEGRATION_SESSION_FAILED"

export const EDIT_INTEGRATION_SESSION_INIT = "integrationSession@EDIT_INTEGRATION_SESSION_INIT"
export const EDIT_INTEGRATION_SESSION_SUCCEDED = "integrationSession@EDIT_INTEGRATION_SESSION_SUCCEDED"
export const EDIT_INTEGRATION_SESSION_FAILED = "integrationSession@EDIT_INTEGRATION_SESSION_FAILED"

export const ENABLE_INTEGRATION_SESSION_INIT = "integrationSession@ENABLE_INTEGRATION_SESSION_INIT"
export const ENABLE_INTEGRATION_SESSION_SUCCEDED = "integrationSession@ENABLE_INTEGRATION_SESSION_SUCCEDED"
export const ENABLE_INTEGRATION_SESSION_FAILED = "integrationSession@ENABLE_INTEGRATION_SESSION_FAILED"

export const DISABLE_INTEGRATION_SESSION_INIT = "integrationSession@DISABLE_INTEGRATION_SESSION_INIT"
export const DISABLE_INTEGRATION_SESSION_SUCCEDED = "integrationSession@DISABLE_INTEGRATION_SESSION_SUCCEDED"
export const DISABLE_INTEGRATION_SESSION_FAILED = "integrationSession@DISABLE_INTEGRATION_SESSION_FAILED"

export const DELETE_INTEGRATION_SESSION_INIT = "integrationSession@DELETE_INTEGRATION_SESSION_INIT"
export const DELETE_INTEGRATION_SESSION_SUCCEDED = "integrationSession@DELETE_INTEGRATION_SESSION_SUCCEDED"
export const DELETE_INTEGRATION_SESSION_FAILED = "integrationSession@DELETE_INTEGRATION_SESSION_FAILED"

export const FETCH_APPOINTMENTS_INIT = "integrationSession@FETCH_APPOINTMENTS_INIT"
export const FETCH_APPOINTMENTS_SUCCEDED = "integrationSession@FETCH_APPOINTMENTS_SUCCEDED"
export const FETCH_APPOINTMENTS_FAILED = "integrationSession@FETCH_APPOINTMENTS_FAILED"

export const CREATE_INTEGRATION_SESSION_APPOINTMENT_INIT = "integrationSession@CREATE_INTEGRATION_SESSION_APPOINTMENT_INIT"
export const CREATE_INTEGRATION_SESSION_APPOINTMENT_SUCCEDED = "integrationSession@CREATE_INTEGRATION_SESSION_APPOINTMENT_SUCCEDED"
export const CREATE_INTEGRATION_SESSION_APPOINTMENT_FAILED = "integrationSession@CREATE_INTEGRATION_SESSION_APPOINTMENT_FAILED"

export const FETCH_INTEGRATION_SESSION_APPOINTMENT_INIT = "integrationSession@FETCH_INTEGRATION_SESSION_APPOINTMENT_INIT"
export const FETCH_INTEGRATION_SESSION_APPOINTMENT_SUCCEDED = "integrationSession@FETCH_INTEGRATION_SESSION_APPOINTMENT_SUCCEDED"
export const FETCH_INTEGRATION_SESSION_APPOINTMENT_FAILED = "integrationSession@FETCH_INTEGRATION_SESSION_APPOINTMENT_FAILED"

export const EDIT_INTEGRATION_SESSION_APPOINTMENT_INIT = "integrationSession@EDIT_INTEGRATION_SESSION_APPOINTMENT_INIT"
export const EDIT_INTEGRATION_SESSION_APPOINTMENT_SUCCEDED = "integrationSession@EDIT_INTEGRATION_SESSION_APPOINTMENT_SUCCEDED"
export const EDIT_INTEGRATION_SESSION_APPOINTMENT_FAILED = "integrationSession@EDIT_INTEGRATION_SESSION_APPOINTMENT_FAILED"

export const CREATE_SKILLS_EVALUATION_INIT = "integrationSession@CREATE_SKILLS_EVALUATION_INIT"
export const CREATE_SKILLS_EVALUATION_SUCCEDED = "integrationSession@CREATE_SKILLS_EVALUATION_SUCCEDED"
export const CREATE_SKILLS_EVALUATION_FAILED = "integrationSession@CREATE_SKILLS_EVALUATION_FAILED"

export const FETCH_SKILLS_EVALUATION_INIT = "integrationSession@FETCH_SKILLS_EVALUATION_INIT"
export const FETCH_SKILLS_EVALUATION_SUCCEDED = "integrationSession@FETCH_SKILLS_EVALUATION_SUCCEDED"
export const FETCH_SKILLS_EVALUATION_FAILED = "integrationSession@FETCH_SKILLS_EVALUATION_FAILED"

export const EDIT_SKILLS_EVALUATION_INIT = "integrationSession@EDIT_SKILLS_EVALUATION_INIT"
export const EDIT_SKILLS_EVALUATION_SUCCEDED = "integrationSession@EDIT_SKILLS_EVALUATION_SUCCEDED"
export const EDIT_SKILLS_EVALUATION_FAILED = "integrationSession@EDIT_SKILLS_EVALUATION_FAILED"
