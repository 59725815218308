export const TRANSFER_REQUEST_PENDING = 1
export const TRANSFER_REQUEST_ACCEPTED = 2 
export const TRANSFER_REQUEST_REFUSED = 3
export const TRANSFER_REQUEST_ACCEPTED_PENDING = 4




export const TRANSFER_REQUEST_STATUS = {
  [TRANSFER_REQUEST_PENDING]: "TRANSFER_REQUEST.PENDING",
  [TRANSFER_REQUEST_ACCEPTED]: "TRANSFER_REQUEST.ACCEPTED",
  [TRANSFER_REQUEST_REFUSED]: "TRANSFER_REQUEST.REFUSED",
  [TRANSFER_REQUEST_ACCEPTED_PENDING]: "TRANSFER_REQUEST.ACCEPTED_PENDING",

}


export const TRANSFER_REQUEST_STATUS_COLOR = {
  [TRANSFER_REQUEST_PENDING]: "primary",
  [TRANSFER_REQUEST_ACCEPTED]: "success",
  [TRANSFER_REQUEST_REFUSED]: "danger",
  [TRANSFER_REQUEST_ACCEPTED_PENDING]: "info",
}

export const transferRequestUIHelper = (intl) =>
  Object.keys(TRANSFER_REQUEST_STATUS).map((key) => ({
    value: parseInt(key),
    label: intl.formatMessage({ id: TRANSFER_REQUEST_STATUS[key] }),
  }))
