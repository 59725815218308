export const NATIONALITY = {
    1: "PARTNER.NATIONALITY.TUNISIAN",
    2: "PARTNER.NATIONALITY.FOREIGN",
}


export const nationalityParternarUIHelper = (intl) =>
    Object.keys(NATIONALITY).map((key) => ({
        value: parseInt(key),
        label: intl.formatMessage({ id: NATIONALITY[key] }),
    }))
