export const CLEAR_ERROR = "profile@CLEAR_ERROR"

export const FETCH_APPOINTMENT_INIT = "profile@FETCH_APPOINTMENT_INIT"
export const FETCH_APPOINTMENT_SUCCEDED = "profile@FETCH_APPOINTMENT_SUCCEDED"
export const FETCH_APPOINTMENT_FAILED = "profile@FETCH_APPOINTMENT_FAILED"

export const FETCH_APPOINTMENTS_INIT = "profile@FETCH_APPOINTMENTS_INIT"
export const FETCH_APPOINTMENTS_SUCCEDED = "profile@FETCH_APPOINTMENTS_SUCCEDED"
export const FETCH_APPOINTMENTS_FAILED = "profile@FETCH_APPOINTMENTS_FAILED"

export const FILTER_APPOINTMENT = "profile@FILTER_APPOINTMENT"
