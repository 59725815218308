import React from "react"
import {ProtectedDialogRoute} from "../routes"

const RenderDialogItems = ({ routes, basePath, params }) => {

  const renderRoute = ({ component, history, match })  => {
    const Component = component
    return <Component 
      params={(match && match.params) ? {...match.params, ...params} : { ...params }} 
      show={match != null} 
      onHide = {() => { history.push(basePath)} } 
      />
  }

  return <>{ Object.keys(routes).map(key => (
    <ProtectedDialogRoute key={key} path={basePath + routes[key].path}>
      {({ history, match }) => renderRoute({ component: routes[key].component, history, match })}
    </ProtectedDialogRoute>
  )) }</>
}


export default RenderDialogItems
