// folderGroup
export const FETCH_FOLDER_GROUPS_INIT = "folderGroup@FETCH_FOLDER_GROUPS_INIT"
export const FETCH_FOLDER_GROUPS_SUCCEDED = "folderGroup@FETCH_FOLDER_GROUPS_SUCCEDED"
export const FETCH_FOLDER_GROUPS_FAILED = "folderGroup@FETCH_FOLDER_GROUPS_FAILED"

export const CLEAR_FOLDER_GROUP = "folderGroup@CLEAR_FOLDER_GROUP"
export const SET_APPOINTMENT_INIT_VALUE = "folderGroup@SET_APPOINTMENT_INIT_VALUE"

export const CREATE_FOLDER_GROUP_INIT = "folderGroup@CREATE_FOLDER_GROUP_INIT"
export const CREATE_FOLDER_GROUP_SUCCEDED = "folderGroup@CREATE_FOLDER_GROUP_SUCCEDED"
export const CREATE_FOLDER_GROUP_FAILED = "folderGroup@CREATE_FOLDER_GROUP_FAILED"

export const FETCH_FOLDER_GROUP_INIT = "folderGroup@FETCH_FOLDER_GROUP_INIT"
export const FETCH_FOLDER_GROUP_SUCCEDED = "folderGroup@FETCH_FOLDER_GROUP_SUCCEDED"
export const FETCH_FOLDER_GROUP_FAILED = "folderGroup@FETCH_FOLDER_GROUP_FAILED"

export const EDIT_FOLDER_GROUP_INIT = "folderGroup@EDIT_FOLDER_GROUP_INIT"
export const EDIT_FOLDER_GROUP_SUCCEDED = "folderGroup@EDIT_FOLDER_GROUP_SUCCEDED"
export const EDIT_FOLDER_GROUP_FAILED = "folderGroup@EDIT_FOLDER_GROUP_FAILED"

export const ENABLE_FOLDER_GROUP_INIT = "folderGroup@ENABLE_FOLDER_GROUP_INIT"
export const ENABLE_FOLDER_GROUP_SUCCEDED = "folderGroup@ENABLE_FOLDER_GROUP_SUCCEDED"
export const ENABLE_FOLDER_GROUP_FAILED = "folderGroup@ENABLE_FOLDER_GROUP_FAILED"

export const DISABLE_FOLDER_GROUP_INIT = "folderGroup@DISABLE_FOLDER_GROUP_INIT"
export const DISABLE_FOLDER_GROUP_SUCCEDED = "folderGroup@DISABLE_FOLDER_GROUP_SUCCEDED"
export const DISABLE_FOLDER_GROUP_FAILED = "folderGroup@DISABLE_FOLDER_GROUP_FAILED"

export const DELETE_FOLDER_GROUP_INIT = "folderGroup@DELETE_FOLDER_GROUP_INIT"
export const DELETE_FOLDER_GROUP_SUCCEDED = "folderGroup@DELETE_FOLDER_GROUP_SUCCEDED"
export const DELETE_FOLDER_GROUP_FAILED = "folderGroup@DELETE_FOLDER_GROUP_FAILED"

export const FETCH_APPOINTMENTS_INIT = "folderGroup@FETCH_APPOINTMENTS_INIT"
export const FETCH_APPOINTMENTS_SUCCEDED = "folderGroup@FETCH_APPOINTMENTS_SUCCEDED"
export const FETCH_APPOINTMENTS_FAILED = "folderGroup@FETCH_APPOINTMENTS_FAILED"

export const CREATE_FOLDER_GROUP_APPOINTMENT_INIT = "folderGroup@CREATE_FOLDER_GROUP_APPOINTMENT_INIT"
export const CREATE_FOLDER_GROUP_APPOINTMENT_SUCCEDED = "folderGroup@CREATE_FOLDER_GROUP_APPOINTMENT_SUCCEDED"
export const CREATE_FOLDER_GROUP_APPOINTMENT_FAILED = "folderGroup@CREATE_FOLDER_GROUP_APPOINTMENT_FAILED"

export const FETCH_FOLDER_GROUP_APPOINTMENT_INIT = "folderGroup@FETCH_FOLDER_GROUP_APPOINTMENT_INIT"
export const FETCH_FOLDER_GROUP_APPOINTMENT_SUCCEDED = "folderGroup@FETCH_FOLDER_GROUP_APPOINTMENT_SUCCEDED"
export const FETCH_FOLDER_GROUP_APPOINTMENT_FAILED = "folderGroup@FETCH_FOLDER_GROUP_APPOINTMENT_FAILED"

export const EDIT_FOLDER_GROUP_APPOINTMENT_INIT = "folderGroup@EDIT_FOLDER_GROUP_APPOINTMENT_INIT"
export const EDIT_FOLDER_GROUP_APPOINTMENT_SUCCEDED = "folderGroup@EDIT_FOLDER_GROUP_APPOINTMENT_SUCCEDED"
export const EDIT_FOLDER_GROUP_APPOINTMENT_FAILED = "folderGroup@EDIT_FOLDER_GROUP_APPOINTMENT_FAILED"

export const CREATE_SKILLS_EVALUATION_INIT = "folderGroup@CREATE_SKILLS_EVALUATION_INIT"
export const CREATE_SKILLS_EVALUATION_SUCCEDED = "folderGroup@CREATE_SKILLS_EVALUATION_SUCCEDED"
export const CREATE_SKILLS_EVALUATION_FAILED = "folderGroup@CREATE_SKILLS_EVALUATION_FAILED"

export const FETCH_SKILLS_EVALUATION_INIT = "folderGroup@FETCH_SKILLS_EVALUATION_INIT"
export const FETCH_SKILLS_EVALUATION_SUCCEDED = "folderGroup@FETCH_SKILLS_EVALUATION_SUCCEDED"
export const FETCH_SKILLS_EVALUATION_FAILED = "folderGroup@FETCH_SKILLS_EVALUATION_FAILED"

export const EDIT_SKILLS_EVALUATION_INIT = "folderGroup@EDIT_SKILLS_EVALUATION_INIT"
export const EDIT_SKILLS_EVALUATION_SUCCEDED = "folderGroup@EDIT_SKILLS_EVALUATION_SUCCEDED"
export const EDIT_SKILLS_EVALUATION_FAILED = "folderGroup@EDIT_SKILLS_EVALUATION_FAILED"
