export const PUBLIC_TYPE = {
    1: "PARTNER.PUBLIC_TYPE.EDUCATIONAL_ESTABLISHMENT",
    2: "PARTNER.PUBLIC_TYPE.PROFESSIONAL_TRAINING_ESTABLISHMENT",
    3: "PARTNER.PUBLIC_TYPE.SOCIAL_AFFAIRS_ESTABLISHMENT",
    4: "PARTNER.PUBLIC_TYPE.OTHER_PUBLIC_ESTABLISHMENT",
    5: "PARTNER.PUBLIC_TYPE.MUNICIPALITY",
    6: "PARTNER.PUBLIC_TYPE.DELEGATION",
    7: "PARTNER.PUBLIC_TYPE.GOVERNORATE"
}


export const publicTypeUIHelper = (intl) =>
    Object.keys(PUBLIC_TYPE).map((key) => ({
        value: parseInt(key),
        label: intl.formatMessage({ id: PUBLIC_TYPE[key] }),
    }))
