// skillsEvaluation
export const FETCH_SKILLS_EVALUATIONS_INIT = "skillsEvaluation@FETCH_SKILLS_EVALUATIONS_INIT"
export const FETCH_SKILLS_EVALUATIONS_SUCCEDED = "skillsEvaluation@FETCH_SKILLS_EVALUATIONS_SUCCEDED"
export const FETCH_SKILLS_EVALUATIONS_FAILED = "skillsEvaluation@FETCH_SKILLS_EVALUATIONS_FAILED"

export const CLEAR_SKILLS_EVALUATION = "skillsEvaluation@CLEAR_SKILLS_EVALUATION"

export const CREATE_SKILLS_EVALUATION_INIT = "skillsEvaluation@CREATE_SKILLS_EVALUATION_INIT"
export const CREATE_SKILLS_EVALUATION_SUCCEDED = "skillsEvaluation@CREATE_SKILLS_EVALUATION_SUCCEDED"
export const CREATE_SKILLS_EVALUATION_FAILED = "skillsEvaluation@CREATE_SKILLS_EVALUATION_FAILED"

export const FETCH_SKILLS_EVALUATION_INIT = "skillsEvaluation@FETCH_SKILLS_EVALUATION_INIT"
export const FETCH_SKILLS_EVALUATION_SUCCEDED = "skillsEvaluation@FETCH_SKILLS_EVALUATION_SUCCEDED"
export const FETCH_SKILLS_EVALUATION_FAILED = "skillsEvaluation@FETCH_SKILLS_EVALUATION_FAILED"

export const EDIT_SKILLS_EVALUATION_INIT = "skillsEvaluation@EDIT_SKILLS_EVALUATION_INIT"
export const EDIT_SKILLS_EVALUATION_SUCCEDED = "skillsEvaluation@EDIT_SKILLS_EVALUATION_SUCCEDED"
export const EDIT_SKILLS_EVALUATION_FAILED = "skillsEvaluation@EDIT_SKILLS_EVALUATION_FAILED"

export const DEACTIVATE_SKILLS_EVALUATION_INIT = "skillsEvaluation@DEACTIVATE_SKILLS_EVALUATION_INIT"
export const DEACTIVATE_SKILLS_EVALUATION_SUCCEDED = "skillsEvaluation@DEACTIVATE_SKILLS_EVALUATION_SUCCEDED"
export const DEACTIVATE_SKILLS_EVALUATION_FAILED = "skillsEvaluation@DEACTIVATE_SKILLS_EVALUATION_FAILED"

export const ACTIVATE_SKILLS_EVALUATION_INIT = "skillsEvaluation@ACTIVATE_SKILLS_EVALUATION_INIT"
export const ACTIVATE_SKILLS_EVALUATION_SUCCEDED = "skillsEvaluation@ACTIVATE_SKILLS_EVALUATION_SUCCEDED"
export const ACTIVATE_SKILLS_EVALUATION_FAILED = "skillsEvaluation@ACTIVATE_SKILLS_EVALUATION_FAILED"
