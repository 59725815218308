// absenceSheetGroup
export const FETCH_ABSENCE_SHEETS_INIT = "absenceSheetGroup@FETCH_ABSENCE_SHEETS_INIT"
export const FETCH_ABSENCE_SHEETS_SUCCEDED = "absenceSheetGroup@FETCH_ABSENCE_SHEETS_SUCCEDED"
export const FETCH_ABSENCE_SHEETS_FAILED = "absenceSheetGroup@FETCH_ABSENCE_SHEETS_FAILED"

export const CLEAR_ABSENCE_SHEET = "absenceSheetGroup@CLEAR_ABSENCE_SHEET"

export const CREATE_ABSENCE_SHEET_INIT = "absenceSheetGroup@CREATE_ABSENCE_SHEET_INIT"
export const CREATE_ABSENCE_SHEET_SUCCEDED = "absenceSheetGroup@CREATE_ABSENCE_SHEET_SUCCEDED"
export const CREATE_ABSENCE_SHEET_FAILED = "absenceSheetGroup@CREATE_ABSENCE_SHEET_FAILED"

export const FETCH_ABSENCE_SHEET_INIT = "absenceSheetGroup@FETCH_ABSENCE_SHEET_INIT"
export const FETCH_ABSENCE_SHEET_SUCCEDED = "absenceSheetGroup@FETCH_ABSENCE_SHEET_SUCCEDED"
export const FETCH_ABSENCE_SHEET_FAILED = "absenceSheetGroup@FETCH_ABSENCE_SHEET_FAILED"

export const EDIT_ABSENCE_SHEET_INIT = "absenceSheetGroup@EDIT_ABSENCE_SHEET_INIT"
export const EDIT_ABSENCE_SHEET_SUCCEDED = "absenceSheetGroup@EDIT_ABSENCE_SHEET_SUCCEDED"
export const EDIT_ABSENCE_SHEET_FAILED = "absenceSheetGroup@EDIT_ABSENCE_SHEET_FAILED"

export const DEACTIVATE_ABSENCE_SHEET_INIT = "absenceSheetGroup@DEACTIVATE_ABSENCE_SHEET_INIT"
export const DEACTIVATE_ABSENCE_SHEET_SUCCEDED = "absenceSheetGroup@DEACTIVATE_ABSENCE_SHEET_SUCCEDED"
export const DEACTIVATE_ABSENCE_SHEET_FAILED = "absenceSheetGroup@DEACTIVATE_ABSENCE_SHEET_FAILED"

export const ACTIVATE_ABSENCE_SHEET_INIT = "absenceSheetGroup@ACTIVATE_ABSENCE_SHEET_INIT"
export const ACTIVATE_ABSENCE_SHEET_SUCCEDED = "absenceSheetGroup@ACTIVATE_ABSENCE_SHEET_SUCCEDED"
export const ACTIVATE_ABSENCE_SHEET_FAILED = "absenceSheetGroup@ACTIVATE_ABSENCE_SHEET_FAILED"
