export const ASSESSMENT_TOOLS = "/api/evaluation-tools"
export const ASSESSMENT_TOOL = "/api/evaluation-tools/:param"

export const ASSESSMENT_TOOL_DEACTIVATE = "/api/evaluation-tools/:param/deactivate"
export const ASSESSMENT_TOOL_ACTIVATE = "/api/evaluation-tools/:param/activate"
export const ASSESSMENT_TOOL_CANCEL = "/api/request-evaluation-tools/:param/cancel"

export const EXPORT_ASSESSMENT_TOOLS = "/api/evaluation-tools/export"
export const IMPORT_ASSESSMENT_TOOLS = "/api/evaluation-tools/import"

export const REQUEST_ASSESSMENT_TOOLS = "/api/request-evaluation-tools"
export const REQUEST_ASSESSMENT_TOOL = "/api/request-evaluation-tools/:param"
