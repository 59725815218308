// postCourseFollowUp
export const FETCH_POST_COURSE_FOLLOWUPS_INIT = "postCourseFollowUp@FETCH_POST_COURSE_FOLLOWUPS_INIT"
export const FETCH_POST_COURSE_FOLLOWUPS_SUCCEDED = "postCourseFollowUp@FETCH_POST_COURSE_FOLLOWUPS_SUCCEDED"
export const FETCH_POST_COURSE_FOLLOWUPS_FAILED = "postCourseFollowUp@FETCH_POST_COURSE_FOLLOWUPS_FAILED"

export const CLEAR_POST_COURSE_FOLLOWUP = "postCourseFollowUp@CLEAR_POST_COURSE_FOLLOWUP"

export const CREATE_POST_COURSE_FOLLOWUP_INIT = "postCourseFollowUp@CREATE_POST_COURSE_FOLLOWUP_INIT"
export const CREATE_POST_COURSE_FOLLOWUP_SUCCEDED = "postCourseFollowUp@CREATE_POST_COURSE_FOLLOWUP_SUCCEDED"
export const CREATE_POST_COURSE_FOLLOWUP_FAILED = "postCourseFollowUp@CREATE_POST_COURSE_FOLLOWUP_FAILED"

export const FETCH_POST_COURSE_FOLLOWUP_INIT = "postCourseFollowUp@FETCH_POST_COURSE_FOLLOWUP_INIT"
export const FETCH_POST_COURSE_FOLLOWUP_SUCCEDED = "postCourseFollowUp@FETCH_POST_COURSE_FOLLOWUP_SUCCEDED"
export const FETCH_POST_COURSE_FOLLOWUP_FAILED = "postCourseFollowUp@FETCH_POST_COURSE_FOLLOWUP_FAILED"

export const EDIT_POST_COURSE_FOLLOWUP_INIT = "postCourseFollowUp@EDIT_POST_COURSE_FOLLOWUP_INIT"
export const EDIT_POST_COURSE_FOLLOWUP_SUCCEDED = "postCourseFollowUp@EDIT_POST_COURSE_FOLLOWUP_SUCCEDED"
export const EDIT_POST_COURSE_FOLLOWUP_FAILED = "postCourseFollowUp@EDIT_POST_COURSE_FOLLOWUP_FAILED"

export const DELETE_POST_COURSE_FOLLOWUP_INIT = "postCourseFollowUp@DELETE_POST_COURSE_FOLLOWUP_INIT"
export const DELETE_POST_COURSE_FOLLOWUP_SUCCEDED = "postCourseFollowUp@DELETE_POST_COURSE_FOLLOWUP_SUCCEDED"
export const DELETE_POST_COURSE_FOLLOWUP_FAILED = "postCourseFollowUp@DELETE_POST_COURSE_FOLLOWUP_FAILED"

export const CLOSE_POST_COURSE_FOLLOWUP_INIT = "postCourseFollowUp@CLOSE_POST_COURSE_FOLLOWUP_INIT"
export const CLOSE_POST_COURSE_FOLLOWUP_SUCCEDED = "postCourseFollowUp@CLOSE_POST_COURSE_FOLLOWUP_SUCCEDED"
export const CLOSE_POST_COURSE_FOLLOWUP_FAILED = "postCourseFollowUp@CLOSE_POST_COURSE_FOLLOWUP_FAILED"