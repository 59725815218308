// contract
export const FETCH_CONTRACTS_INIT = "contrat@FETCH_CONTRACTS_INIT"
export const FETCH_CONTRACTS_SUCCEDED = "contrat@FETCH_CONTRACTS_SUCCEDED"
export const FETCH_CONTRACTS_FAILED = "contrat@FETCH_CONTRACTS_FAILED"

export const CLEAR_CONTRACTS = "contrat@CLEAR_CONTRACT"

export const CREATE_CONTRACT_INIT = "contrat@CREATE_CONTRACT_INIT"
export const CREATE_CONTRACT_SUCCEDED = "contrat@CREATE_CONTRACT_SUCCEDED"
export const CREATE_CONTRACT_FAILED = "contrat@CREATE_CONTRACT_FAILED"

export const FETCH_CONTRACT_INIT = "contrat@FETCH_CONTRACT_INIT"
export const FETCH_CONTRACT_SUCCEDED = "contrat@FETCH_CONTRACT_SUCCEDED"
export const FETCH_CONTRACT_FAILED = "contrat@FETCH_CONTRACT_FAILED"

export const EDIT_CONTRACT_INIT = "contrat@EDIT_CONTRACT_INIT"
export const EDIT_CONTRACT_SUCCEDED = "contrat@EDIT_CONTRACT_SUCCEDED"
export const EDIT_CONTRACT_FAILED = "contrat@EDIT_CONTRACT_FAILED"

export const DELETE_CONTRACT_INIT = "contrat@DELETE_CONTRACT_INIT"
export const DELETE_CONTRACT_SUCCEDED = "contrat@DELETE_CONTRACT_SUCCEDED"
export const DELETE_CONTRACT_FAILED = "contrat@DELETE_CONTRACT_FAILED"


