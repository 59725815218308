export const DIMENSION = {
  1: 'PARTNER.DIMENSION.NONE',
  2: 'PARTNER.DIMENSION.LESS_THAN_10',
  3: 'PARTNER.DIMENSION.BETWEEN_10_19',
  4: 'PARTNER.DIMENSION.BETWEEN_20_49',
  5: 'PARTNER.DIMENSION.BETWEEN_50_99',
  6: 'PARTNER.DIMENSION.BETWEEN_100_199',
  7: 'PARTNER.DIMENSION.BETWEEN_200_PLUS'
}

export const DimensionSocietyUIHelper = (intl) =>
  Object.keys(DIMENSION).map((key) => ({
    value: parseInt(key),
    label: intl.formatMessage({ id: DIMENSION[key] })
  }))
