// evaluationRubric
export const FETCH_EVALUATION_RUBRICS_INIT = "evaluationRubric@FETCH_EVALUATION_RUBRICS_INIT"
export const FETCH_EVALUATION_RUBRICS_SUCCEDED = "evaluationRubric@FETCH_EVALUATION_RUBRICS_SUCCEDED"
export const FETCH_EVALUATION_RUBRICS_FAILED = "evaluationRubric@FETCH_EVALUATION_RUBRICS_FAILED"

export const CLEAR_EVALUATION_RUBRIC = "evaluationRubric@CLEAR_EVALUATION_RUBRIC"

export const CREATE_EVALUATION_RUBRIC_INIT = "evaluationRubric@CREATE_EVALUATION_RUBRIC_INIT"
export const CREATE_EVALUATION_RUBRIC_SUCCEDED = "evaluationRubric@CREATE_EVALUATION_RUBRIC_SUCCEDED"
export const CREATE_EVALUATION_RUBRIC_FAILED = "evaluationRubric@CREATE_EVALUATION_RUBRIC_FAILED"

export const FETCH_EVALUATION_RUBRIC_INIT = "evaluationRubric@FETCH_EVALUATION_RUBRIC_INIT"
export const FETCH_EVALUATION_RUBRIC_SUCCEDED = "evaluationRubric@FETCH_EVALUATION_RUBRIC_SUCCEDED"
export const FETCH_EVALUATION_RUBRIC_FAILED = "evaluationRubric@FETCH_EVALUATION_RUBRIC_FAILED"

export const EDIT_EVALUATION_RUBRIC_INIT = "evaluationRubric@EDIT_EVALUATION_RUBRIC_INIT"
export const EDIT_EVALUATION_RUBRIC_SUCCEDED = "evaluationRubric@EDIT_EVALUATION_RUBRIC_SUCCEDED"
export const EDIT_EVALUATION_RUBRIC_FAILED = "evaluationRubric@EDIT_EVALUATION_RUBRIC_FAILED"

export const DEACTIVATE_EVALUATION_RUBRIC_INIT = "evaluationRubric@DEACTIVATE_EVALUATION_RUBRIC_INIT"
export const DEACTIVATE_EVALUATION_RUBRIC_SUCCEDED = "evaluationRubric@DEACTIVATE_EVALUATION_RUBRIC_SUCCEDED"
export const DEACTIVATE_EVALUATION_RUBRIC_FAILED = "evaluationRubric@DEACTIVATE_EVALUATION_RUBRIC_FAILED"

export const CANCEL_EVALUATION_RUBRIC_INIT = "evaluationRubric@CANCEL_EVALUATION_RUBRIC_INIT"
export const CANCEL_EVALUATION_RUBRIC_SUCCEDED = "evaluationRubric@CANCEL_EVALUATION_RUBRIC_SUCCEDED"
export const CANCEL_EVALUATION_RUBRIC_FAILED = "evaluationRubric@CANCEL_EVALUATION_RUBRIC_FAILED"

export const ACTIVATE_EVALUATION_RUBRIC_INIT = "evaluationRubric@ACTIVATE_EVALUATION_RUBRIC_INIT"
export const ACTIVATE_EVALUATION_RUBRIC_SUCCEDED = "evaluationRubric@ACTIVATE_EVALUATION_RUBRIC_SUCCEDED"
export const ACTIVATE_EVALUATION_RUBRIC_FAILED = "evaluationRubric@ACTIVATE_EVALUATION_RUBRIC_FAILED"


// request evaluation rubric
export const FETCH_REQUEST_EVALUATION_RUBRICS_INIT = "requestEvaluationRubric@FETCH_REQUEST_EVALUATION_RUBRICS_INIT"
export const FETCH_REQUEST_EVALUATION_RUBRICS_SUCCEDED = "requestEvaluationRubric@FETCH_REQUEST_EVALUATION_RUBRICS_SUCCEDED"
export const FETCH_REQUEST_EVALUATION_RUBRICS_FAILED = "requestEvaluationRubric@FETCH_REQUEST_EVALUATION_RUBRICS_FAILED"

export const FETCH_REQUEST_EVALUATION_RUBRIC_INIT = "requestEvaluationRubric@FETCH_REQUEST_EVALUATION_RUBRIC_INIT"
export const FETCH_REQUEST_EVALUATION_RUBRIC_SUCCEDED = "requestEvaluationRubric@FETCH_REQUEST_EVALUATION_RUBRIC_SUCCEDED"
export const FETCH_REQUEST_EVALUATION_RUBRIC_FAILED = "requestEvaluationRubric@FETCH_REQUEST_EVALUATION_RUBRIC_FAILED"

export const CREATE_REQUEST_EVALUATION_RUBRIC_INIT = "requestEvaluationRubric@CREATE_REQUEST_EVALUATION_RUBRIC_INIT"
export const CREATE_REQUEST_EVALUATION_RUBRIC_SUCCEDED = "requestEvaluationRubric@CREATE_REQUEST_EVALUATION_RUBRIC_SUCCEDED"
export const CREATE_REQUEST_EVALUATION_RUBRIC_FAILED = "requestEvaluationRubric@CREATE_REQUEST_EVALUATION_RUBRIC_FAILED"

export const EDIT_REQUEST_EVALUATION_RUBRIC_INIT = "requestEvaluationRubric@EDIT_REQUEST_EVALUATION_RUBRIC_INIT"
export const EDIT_REQUEST_EVALUATION_RUBRIC_SUCCEDED = "requestEvaluationRubric@EDIT_REQUEST_EVALUATION_RUBRIC_SUCCEDED"
export const EDIT_REQUEST_EVALUATION_RUBRIC_FAILED = "requestEvaluationRubric@EDIT_REQUEST_EVALUATION_RUBRIC_FAILED"

