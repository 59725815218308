/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from "redux"

// Reducers
import folderReducer from "./folderReducer"
import transferRequestReducer from "./transferRequestReducer"
import eventReducer from "./../../containers/event/store/reducers"
import postCourseFollowUpReducer from "./../../containers/post-course-follow-up/store/reducers"
import skillsEvaluationFolderReducer from "./../../containers/skills-evaluation/store/reducers"
import orientationReducer from "./../../containers/orientation/store/reducers"
import familyMemberReducer from "./../../containers/family-member/store/reducers"
import folderDocumentReducer from "./../../containers/folder-document/store/reducers"

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export default combineReducers({
  transferRequest: transferRequestReducer,
  folder: folderReducer,
  event: eventReducer,
  postCourseFollowUp: postCourseFollowUpReducer,
  skillsEvaluationFolder: skillsEvaluationFolderReducer,
  orientation: orientationReducer,
  folderDocument: folderDocumentReducer,
  familyMember: familyMemberReducer
})

