import _ from "lodash"

import { HTTP_METHODS } from "../../../../../constants"
import { makeCall } from "../../../../../helpers"
import {isRLTLang} from "../../../../../i18n"
import { store } from "../../../../../configureStore"


const FETCH_SKILLS_EVALUATION_SERVICES_ENDPOINT = "/api/folders/:param/skills-evaluations/all"

const formatOrientationSkillsEvaluation = (skillsEvaluations) => skillsEvaluations.map((skillsEvaluation) =>
    {
        return{
        label: isRLTLang() ? skillsEvaluation.fullEntitledLabel.valueAr : skillsEvaluation.fullEntitledLabel.valueFr ,
        value: skillsEvaluation.id
    }}
)


export const orientationSkillsEvaluationsUIHelper = (callback,integration_session,param) => {
    let endpoint = FETCH_SKILLS_EVALUATION_SERVICES_ENDPOINT.replace(":param",param)
    const { token } = store.getState().common.auth || {}
    let query = {}
    query.integrationSession = integration_session

    return new Promise((resolve, reject) =>
        makeCall(HTTP_METHODS.GET, endpoint, {}, {'Authorization': `Bearer  ${token.access}`}, query)
            .then(resp => {
                let result = {}
                if (_.isPlainObject(resp.data)){
                    result = [resp.data]
                }else {
                    result = resp.data
                }
                resolve(callback(formatOrientationSkillsEvaluation( result[0].results )))
            })
            .catch(err => reject(err.response))
    )
}
