import _ from "lodash"

import { HTTP_METHODS } from "./../../../../../constants"
import { makeExternalCall } from "./../../../../../helpers"
import { isRLTLang } from "../../../../../i18n"


const FETCH_OPTIONS_ENDPOINT = "/api/pa/options/list?list_number=20"

const formatOptions = (options) => options.map((option) => (
  { label: isRLTLang() ? option.valueAr : option.valueFr, value: option.rank }))


export const relationShipUIHelper = (callback, keyword) => {

  let endpoint = FETCH_OPTIONS_ENDPOINT
  let query = {}
  if (!_.isEmpty(keyword)) {
    query = { q: keyword }
  }

  return new Promise((resolve, reject) =>
    makeExternalCall(HTTP_METHODS.GET, endpoint, {}, {}, query)
      .then(resp => {
        let result = {}
        if (_.isPlainObject(resp.data)) {
          result = [resp.data]
        } else {
          result = resp.data
        }
        resolve(callback(formatOptions(result)))
      })
      .catch(err => reject(err.response))
  )
}


export const RELATIONSHIP = { 
  1: "FOLDER.FAMILTY_INFORMATION.GOOD_RELATIONSHIP_INDICATION",
  2: "FOLDER.FAMILTY_INFORMATION.NORMAL_RELATIONSHIP_INDICATION",
  3: "FOLDER.FAMILTY_INFORMATION.NERVOUS_RELATIONSHIP_INDICATION",
  4: "FOLDER.FAMILTY_INFORMATION.HOSTILITY_RELATIONSHIP_INDICATION",
  5: "FOLDER.FAMILTY_INFORMATION.BREAK_RELATIONSHIP_INDICATION",
}

/* export const relationShipUIHelper = (intl) =>
  Object.keys(RELATIONSHIP).map((value) => ({
    value: parseInt(value),
    label: intl.formatMessage({ id: RELATIONSHIP[value] }),
  })) */
