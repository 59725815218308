

import _ from "lodash"

import { HTTP_METHODS } from "./../../../../../constants"
import { makeExternalCall } from "./../../../../../helpers"
import { isRLTLang } from "../../../../../i18n"


const FETCH_OPTIONS_ENDPOINT = "/api/pa/options/list?list_number=15"

const formatOptions = (options) => options.map((option) => (
  { label: isRLTLang() ? option.valueAr : option.valueFr, value: option.rank }))


export const schoolTypeUIHelper = (callback, keyword) => {

  let endpoint = FETCH_OPTIONS_ENDPOINT
  let query = {}
  if (!_.isEmpty(keyword)) {
    query = { q: keyword }
  }

  return new Promise((resolve, reject) =>
    makeExternalCall(HTTP_METHODS.GET, endpoint, {}, {}, query)
      .then(resp => {
        let result = {}
        if (_.isPlainObject(resp.data)) {
          result = [resp.data]
        } else {
          result = resp.data
        }
        resolve(callback(formatOptions(result)))
      })
      .catch(err => reject(err.response))
  )
}

export const SCHOOL_TYPE_PUBLIC = 1
export const SCHOOL_TYPE_PRIVATE = 2
export const SCHOOL_TYPE_ONG = 3
export const SCHOOL_TYPE_ORGANIZATION = 4


export const SCHOOL_TYPE = {
  [SCHOOL_TYPE_PUBLIC]: "SCHOOL_TYPE.PUBLIC",
  [SCHOOL_TYPE_PRIVATE]: "SCHOOL_TYPE.PRIVATE",
  [SCHOOL_TYPE_ONG]: "SCHOOL_TYPE.ONG" ,
}



/* export const schoolTypeUIHelper = (intl) =>
  Object.keys(SCHOOL_TYPE).map((key) => ({
    value: parseInt(key),
    label: intl.formatMessage({ id: SCHOOL_TYPE[key] }),
  })) */
