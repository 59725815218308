export const SKILL_REQUEST_NEW = 1
export const SKILL_REQUEST_VALID = 2
export const SKILL_REQUEST_REJECTED = 3
export const SKILL_REQUEST_CANCELED = 4

export const SKILL_REQUEST_STATUS = {
  [SKILL_REQUEST_NEW]: "SKILL_REQUEST.STATUS.NEW",
  [SKILL_REQUEST_VALID]: "SKILL_REQUEST.STATUS.VALID",
  [SKILL_REQUEST_REJECTED]: "SKILL_REQUEST.STATUS.REJECTED",
  [SKILL_REQUEST_CANCELED]: "SKILL_REQUEST.STATUS.CANCELED"
}

export const SKILL_REQUEST_STATUS_COLOR = {
  [SKILL_REQUEST_NEW]: "primary",
  [SKILL_REQUEST_VALID]: "success",
  [SKILL_REQUEST_REJECTED]: "danger",
  [SKILL_REQUEST_CANCELED]: "warning"
}

export const skillRequestStatusUIHelper = (intl) =>
  Object.keys(SKILL_REQUEST_STATUS).map((key) => ({
    value: parseInt(key),
    label: intl.formatMessage({ id: SKILL_REQUEST_STATUS[key] }),
  }))
