// familyMember
export const FETCH_FAMILY_MEMBERS_INIT = "familyMember@FETCH_FAMILY_MEMBERS_INIT"
export const FETCH_FAMILY_MEMBERS_SUCCEDED = "familyMember@FETCH_FAMILY_MEMBERS_SUCCEDED"
export const FETCH_FAMILY_MEMBERS_FAILED = "familyMember@FETCH_FAMILY_MEMBERS_FAILED"

export const CLEAR_FAMILY_MEMBER = "familyMember@CLEAR_FAMILY_MEMBER"

export const CREATE_FAMILY_MEMBER_INIT = "familyMember@CREATE_FAMILY_MEMBER_INIT"
export const CREATE_FAMILY_MEMBER_SUCCEDED = "familyMember@CREATE_FAMILY_MEMBER_SUCCEDED"
export const CREATE_FAMILY_MEMBER_FAILED = "familyMember@CREATE_FAMILY_MEMBER_FAILED"

export const FETCH_FAMILY_MEMBER_INIT = "familyMember@FETCH_FAMILY_MEMBER_INIT"
export const FETCH_FAMILY_MEMBER_SUCCEDED = "familyMember@FETCH_FAMILY_MEMBER_SUCCEDED"
export const FETCH_FAMILY_MEMBER_FAILED = "familyMember@FETCH_FAMILY_MEMBER_FAILED"

export const EDIT_FAMILY_MEMBER_INIT = "familyMember@EDIT_FAMILY_MEMBER_INIT"
export const EDIT_FAMILY_MEMBER_SUCCEDED = "familyMember@EDIT_FAMILY_MEMBER_SUCCEDED"
export const EDIT_FAMILY_MEMBER_FAILED = "familyMember@EDIT_FAMILY_MEMBER_FAILED"

export const DELETE_FAMILY_MEMBER_INIT = "familyMember@DELETE_FAMILY_MEMBER_INIT"
export const DELETE_FAMILY_MEMBER_SUCCEDED = "familyMember@DELETE_FAMILY_MEMBER_SUCCEDED"
export const DELETE_FAMILY_MEMBER_FAILED = "familyMember@DELETE_FAMILY_MEMBER_FAILED"

export const CLOSE_FAMILY_MEMBER_INIT = "familyMember@CLOSE_FAMILY_MEMBER_INIT"
export const CLOSE_FAMILY_MEMBER_SUCCEDED = "familyMember@CLOSE_FAMILY_MEMBER_SUCCEDED"
export const CLOSE_FAMILY_MEMBER_FAILED = "familyMember@CLOSE_FAMILY_MEMBER_FAILED"