import _ from "lodash"

import { HTTP_METHODS } from "./../../../../../constants"
import { makeCall } from "./../../../../../helpers"
import { store } from "./../../../../../configureStore"


const FETCH_OPTIONS_ENDPOINT = "/api/powerBI_url"


export const powerBIUrlUIHelper = (callback, keyword) => {

  let endpoint = FETCH_OPTIONS_ENDPOINT
  let query = {}
  if (!_.isEmpty(keyword)) {
    query = { q: keyword }
  }
  const { token } = store.getState().common.auth || {}

  return new Promise((resolve, reject) =>
    makeCall(HTTP_METHODS.GET, endpoint, {}, {'Authorization': `Bearer ${token.access}`}, query)
      .then(resp => {
        let result = ""
        if (_.isPlainObject(resp.data)) {
          result = [resp.data]
        } else {
          result = resp.data
        }
        resolve(callback(result))
      })
      .catch(err => reject(err.response))
  )
}