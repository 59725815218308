//import {PROFESSIONAL_LEARNING} from "./orientationSituationTypeUIHelper";

export const ESTABLISHMENTS = {
  1: "ORIENTATION.ESTABLISHMENT.MINISTRY_EDUCATION_INDICATION",
  2: "ORIENTATION.ESTABLISHMENT.TRAINING_CENTER_INDICATION",
  3: "ORIENTATION.ESTABLISHMENT.CDIS_INDICATION",
  4: "ORIENTATION.ESTABLISHMENT.ILTAL9_INDICATION",
  // 5: "ORIENTATION.ESTABLISHMENT.PRIMARY_SCHOOL",
  // 6: "ORIENTATION.ESTABLISHMENT.PREPARATORY_SCHOOL",
  // 7: "ORIENTATION.ESTABLISHMENT.HIGH_SCHOOL",
  // 8: "ORIENTATION.ESTABLISHMENT.ATFP",
  // 9: "ORIENTATION.ESTABLISHMENT.MILITARY",
  // 10: "ORIENTATION.ESTABLISHMENT.TOURISM",
  // 11: "ORIENTATION.ESTABLISHMENT.AGRICULTURE",
  // 12: "ORIENTATION.ESTABLISHMENT.DEFENSE_AND_SOCIAL_INTEGRATION_CENTER",
  // 13: "ORIENTATION.ESTABLISHMENT.ASSOCIATION",
}
export const GO_BACK_SCHOOL = {
  5: "ORIENTATION.ESTABLISHMENT.PRIMARY_SCHOOL",
  6: "ORIENTATION.ESTABLISHMENT.PREPARATORY_SCHOOL",
  7: "ORIENTATION.ESTABLISHMENT.HIGH_SCHOOL",
}
export const PROFESSIONAL_TRAINING = {
  8: "ORIENTATION.ESTABLISHMENT.ATFP",
  9: "ORIENTATION.ESTABLISHMENT.MILITARY",
  10: "ORIENTATION.ESTABLISHMENT.TOURISM",
  11: "ORIENTATION.ESTABLISHMENT.AGRICULTURE",
}
export const SOCIAL_AFFAIRS_STRUCTURE = {
  12: "ORIENTATION.ESTABLISHMENT.DEFENSE_AND_SOCIAL_INTEGRATION_CENTER",
  13: "ORIENTATION.ESTABLISHMENT.ASSOCIATION",
}
export const ALL_ESTABLISHMENTS = Object.assign(ESTABLISHMENTS,SOCIAL_AFFAIRS_STRUCTURE,PROFESSIONAL_TRAINING,GO_BACK_SCHOOL)
export const orientationToEstablishmentUIHelper = (callback,situationType,intl) =>{
  var res;
  if (situationType === 1)
  {
    res=  Object.keys(ESTABLISHMENTS).map((key) => ({
    value: parseInt(key),
    label: intl.formatMessage({ id: ESTABLISHMENTS[key] }),
  }))}
  else if (situationType ===4)
  {res=  Object.keys(GO_BACK_SCHOOL).map((key) => ({
    value: parseInt(key),
    label: intl.formatMessage({ id: GO_BACK_SCHOOL[key] }),
  }))}
  else if (situationType ===5)
  {res=  Object.keys(PROFESSIONAL_TRAINING).map((key) => ({
    value: parseInt(key),
    label: intl.formatMessage({ id: PROFESSIONAL_TRAINING[key] }),
  }))}
  else if (situationType ===6)
  {res=  Object.keys(PROFESSIONAL_TRAINING).map((key) => ({
    value: parseInt(key),
    label: intl.formatMessage({ id: PROFESSIONAL_TRAINING[key] }),
  }))}
  else if (situationType ===7)
  {res=  Object.keys(SOCIAL_AFFAIRS_STRUCTURE).map((key) => ({
    value: parseInt(key),
    label: intl.formatMessage({ id: SOCIAL_AFFAIRS_STRUCTURE[key] }),
  }))}
  else {
    res =Object.keys(ALL_ESTABLISHMENTS).map((key) => ({
      value: parseInt(key),
      label: intl.formatMessage({ id: ALL_ESTABLISHMENTS[key] }),
    }))}

  return new Promise((resolve, reject) =>resolve(callback(res)))

}

export const orientationToEstablishmentDisplayUIHelper = (intl) =>
  Object.keys(ALL_ESTABLISHMENTS).map((key) => ({
    value: parseInt(key),
    label: intl.formatMessage({ id: ALL_ESTABLISHMENTS[key] }),
  }))


