export const FOLDERS = "/api/all-folders"
export const FOLDER = "/api/all-folders/:param"

export const FOLDER_TRANSFER = "/api/all-folders/:param/cancel-transfer"
export const FOLDER_CANCEL_TRANSFER = "/api/all-folders/:param/cancel-transfer"

export const TRANSFER_REQUESTS = "/api/folder-transfer-requests"
export const TRANSFER_REQUEST = "/api/folder-transfer-requests/:param"
export const TRANSFER_REQUEST_ACCEPTE = "/api/folder-transfer-requests/:param/accept"
export const TRANSFER_REQUEST_REFUSE = "/api/folder-transfer-requests/:param/refuse"

export const FOLDER_ACADEMIC_PROFESSIONAL_PATH = "/api/all-folders/:param/academic-professional-path"
export const FOLDER_FAMILY_IDENTIFICATION = "/api/all-folders/:param/family-information"
export const FOLDER_PARENT_INFORMATION = "/api/all-folders/:param/family-information/parents"
export const FOLDER_EDIT_PARENT_INFORMATION = "/api/parents/:param"
export const FOLDER_PERSONAL_DATA = "/api/all-folders/:param"
export const FOLDER_SOCIAL_AND_PHYSICAL_DATA = "/api/all-folders/:param/social-financial-situation" 
export const FOLDER_ACTION_PLAN = "/api/all-folders/:param/action-plan" 
export const FOLDER_HOWAMI = "/api/all-folders/:param/beneficiary-description"
export const FOLDER_EXIT = "/api/all-folders/:param/exit-gu"
