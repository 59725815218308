
export const TEMPLATE_CONTEXT = {
  2: "PARTENAR_TEMPLATE_CONTRAT",
  3: "TEMPLATE_COMMITMENT_CONTRACT",
  4: "TEMPLATE_RULES_OF_PROCEDURE",
}

export const TEMPLATE_CONTEXT_COLOR = {
  2: "primary",
  3: "success",
  4: "info"
}


export const templateContextUIHelper = (intl) =>
  Object.keys(TEMPLATE_CONTEXT).map((key) => ({
    value: parseInt(key),
    label: intl.formatMessage({ id: TEMPLATE_CONTEXT[key] }),
  }))
