import {isRLTLang} from "../../../../i18n"
import { HTTP_METHODS } from "./../../../../constants"
import { makeExternalCall } from "./../../../../helpers"

const FETCH_STRUCTURE_ENDPOINT = "/api/pa/all-structures?type=:type"


const formatStructure = (structures) => structures.map((structure) => 
  ({ label: isRLTLang() ? structure.titleAr : structure.titleFr, value: structure.reference }) )


export const structureUIHelper = (callback=f=>f,type) => {
  let endpoint = FETCH_STRUCTURE_ENDPOINT.replace(":type",type)
  return new Promise((resolve, reject) =>
    makeExternalCall(HTTP_METHODS.GET, endpoint )
    .then(resp => {
      resolve(callback( formatStructure(resp.data) ))
    })
    .catch(() => reject(callback([])))
  )
}
