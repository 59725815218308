
export const  INTERNAL = 1
export const EXTERNAL = 2

export const TYPES_ANIMATOR = { 
  [INTERNAL]: "INTEGRATION_SESSION.TYPE_ANIMATOR.INTERNAL",
  [EXTERNAL]: "INTEGRATION_SESSION.TYPE_ANIMATOR.EXTERNAL",
}

export const animatorTypeUIHelper = (intl) =>
  Object.keys(TYPES_ANIMATOR).map((key) => ({
    value: parseInt(key),
    label: intl.formatMessage({ id: TYPES_ANIMATOR[key] }),
  }))
