import _ from "lodash"

import { HTTP_METHODS } from "./../../../../../constants"
import { makeExternalCall } from "./../../../../../helpers"
import { isRLTLang } from "../../../../../i18n"


const FETCH_OPTIONS_ENDPOINT = "/api/pa/options/list?list_number=16"

const formatOptions = (options) => options.map((option) => (
  { label: isRLTLang() ? option.valueAr : option.valueFr, value: option.rank }))


export const schoolDropoutCamingOriginUIHelper = (callback, keyword) => {

  let endpoint = FETCH_OPTIONS_ENDPOINT
  let query = {}
  if (!_.isEmpty(keyword)) {
    query = { q: keyword }
  }

  return new Promise((resolve, reject) =>
    makeExternalCall(HTTP_METHODS.GET, endpoint, {}, {}, query)
      .then(resp => {
        let result = {}
        if (_.isPlainObject(resp.data)) {
          result = [resp.data]
        } else {
          result = resp.data
        }
        resolve(callback(formatOptions(result)))
      })
      .catch(err => reject(err.response))
  )
}

export const CAME_ORIGIN = {
  1: "SCHOOL_DROPOUT.CAME_ORIGIN.SPONTANEOUS_PREREGISTRATION",
  2: "SCHOOL_DROPOUT.CAME_ORIGIN.PREREGISTRATION_PARENTS_GUARDIANS",
  3: "SCHOOL_DROPOUT.CAME_ORIGIN.CONTACT_MA3AK",
  4: "SCHOOL_DROPOUT.CAME_ORIGIN.PUBLIC_EDUCATION",
  5: "SCHOOL_DROPOUT.CAME_ORIGIN.PRIVATE_EDUCATION",
  6: "SCHOOL_DROPOUT.CAME_ORIGIN.PUBLIC_VOCATIONAL_TRAINING",
  7: "SCHOOL_DROPOUT.CAME_ORIGIN.PRIVATE_VOCATIONAL_TRAINING",
  8: "SCHOOL_DROPOUT.CAME_ORIGIN.ASSOCIATION",
  9: "SCHOOL_DROPOUT.CAME_ORIGIN.MUNICIPALITY",
  10: "SCHOOL_DROPOUT.CAME_ORIGIN.OTHER"
}

/* export const schoolDropoutCamingOriginUIHelper = (intl) =>
  Object.keys(CAME_ORIGIN).map((key) => ({
    value: parseInt(key),
    label: intl.formatMessage({ id: CAME_ORIGIN[key] }),
  })) */
