  
  import _ from "lodash"

  import { HTTP_METHODS } from "./../../../../../constants"
  import { makeExternalCall } from "./../../../../../helpers"
  import { isRLTLang } from "../../../../../i18n"
  
  
  const FETCH_OPTIONS_ENDPOINT = "/api/pa/options/list?list_number=17"
  
  const formatOptions = (options) => options.map((option) => (
    { label: isRLTLang() ? option.valueAr : option.valueFr, value: option.rank }))
  
  
  export const goalsTypesUIHelper = (callback, keyword) => {
  
    let endpoint = FETCH_OPTIONS_ENDPOINT
    let query = {}
    if (!_.isEmpty(keyword)) {
      query = { q: keyword }
    }
  
    return new Promise((resolve, reject) =>
      makeExternalCall(HTTP_METHODS.GET, endpoint, {}, {}, query)
        .then(resp => {
          let result = {}
          if (_.isPlainObject(resp.data)) {
            result = [resp.data]
          } else {
            result = resp.data
          }
          resolve(callback(formatOptions(result)))
        })
        .catch(err => reject(err.response))
    )
  }

  export const GOALS_TYPES = { 
    1: "GOALS_TYPE.RETURN_TO_SCHOOL_GOALS_TYPE",
    2: "GOALS_TYPE.VOCATIONAL_TRAINING_GOALS_TYPE",
    3: "GOALS_TYPE.GO_JOB_GOALS_TYPE",
  }

/* export const goalsTypesUIHelper = (intl) =>
    Object.keys(GOALS_TYPES).map((key) => ({
      value: parseInt(key),
      label: intl.formatMessage({ id: GOALS_TYPES[key] }),
    })) */
  
    