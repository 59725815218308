import _ from "lodash"

import { HTTP_METHODS } from "./../../../../constants"
import { makeExternalCall } from "./../../../../helpers"
import { isRLTLang } from "../../../../i18n"


const FETCH_OPTIONS_ENDPOINT = "/api/pa/options/list?list_number=7"

const formatOptions = (options) => options.map((option) => (
  { label: isRLTLang() ? option.valueAr : option.valueFr, value: option.rank }))


export const documentTypeUIHelper = (callback, keyword) => {

  let endpoint = FETCH_OPTIONS_ENDPOINT
  let query = {}
  if (!_.isEmpty(keyword)) {
    query = { q: keyword }
  }

  return new Promise((resolve, reject) =>
    makeExternalCall(HTTP_METHODS.GET, endpoint, {}, {}, query)
      .then(resp => {
        let result = {}
        if (_.isPlainObject(resp.data)) {
          result = [resp.data]
        } else {
          result = resp.data
        }
        resolve(callback(formatOptions(result)))
      })
      .catch(err => reject(err.response))
  )
    }




    const formatDocumentOptions = (options) => {
      let values = {}
      options.forEach((option) => {
          values[option.rank] = isRLTLang() ? option.valueAr : option.valueFr
      })
      return values;
  }
  
  
  export const getDocumentOptions = (callback, keyword) => {

    let endpoint = FETCH_OPTIONS_ENDPOINT
    let query = {}
    if (!_.isEmpty(keyword)) {
      query = { q: keyword }
    }
  
    return new Promise((resolve, reject) =>
      makeExternalCall(HTTP_METHODS.GET, endpoint, {}, {}, query)
        .then(resp => {
          let result = {}
          if (_.isPlainObject(resp.data)) {
            result = [resp.data]
          } else {
            result = resp.data
          }
          resolve(callback(formatDocumentOptions(result)))
        })
        .catch(err => reject(err.response))
    )
      }


/* export const DOCUMENTS = {
  1: "FOLDER.DOCUMENT.EXTRAIT_NAISSANCE",
  2: "FOLDER.DOCUMENT.CIN",
  3: "FOLDER.DOCUMENT.CERTIFICAT_SCOLAIRE",
  4: "FOLDER.DOCUMENT.DERNIER_RELEVE_NOTE",
  5: "FOLDER.DOCUMENT.PHOTO_IDENTITE",
  6: "FOLDER.DOCUMENT.OTHER"
}


export const documentTypeUIHelper = (intl) =>
  Object.keys(DOCUMENTS).map((value) => ({
    value: parseInt(value),
    label: intl.formatMessage({ id: DOCUMENTS[value] }),
  }))
 */