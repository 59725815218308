// periodUnavailability
export const FETCH_PERIOD_UNAVAILABILITYS_INIT = "periodUnavailability@FETCH_PERIOD_UNAVAILABILITYS_INIT"
export const FETCH_PERIOD_UNAVAILABILITYS_SUCCEDED = "periodUnavailability@FETCH_PERIOD_UNAVAILABILITYS_SUCCEDED"
export const FETCH_PERIOD_UNAVAILABILITYS_FAILED = "periodUnavailability@FETCH_PERIOD_UNAVAILABILITYS_FAILED"

export const CLEAR_PERIOD_UNAVAILABILITY = "periodUnavailability@CLEAR_PERIOD_UNAVAILABILITY"

export const CREATE_PERIOD_UNAVAILABILITY_INIT = "periodUnavailability@CREATE_PERIOD_UNAVAILABILITY_INIT"
export const CREATE_PERIOD_UNAVAILABILITY_SUCCEDED = "periodUnavailability@CREATE_PERIOD_UNAVAILABILITY_SUCCEDED"
export const CREATE_PERIOD_UNAVAILABILITY_FAILED = "periodUnavailability@CREATE_PERIOD_UNAVAILABILITY_FAILED"

export const FETCH_PERIOD_UNAVAILABILITY_INIT = "periodUnavailability@FETCH_PERIOD_UNAVAILABILITY_INIT"
export const FETCH_PERIOD_UNAVAILABILITY_SUCCEDED = "periodUnavailability@FETCH_PERIOD_UNAVAILABILITY_SUCCEDED"
export const FETCH_PERIOD_UNAVAILABILITY_FAILED = "periodUnavailability@FETCH_PERIOD_UNAVAILABILITY_FAILED"

export const EDIT_PERIOD_UNAVAILABILITY_INIT = "periodUnavailability@EDIT_PERIOD_UNAVAILABILITY_INIT"
export const EDIT_PERIOD_UNAVAILABILITY_SUCCEDED = "periodUnavailability@EDIT_PERIOD_UNAVAILABILITY_SUCCEDED"
export const EDIT_PERIOD_UNAVAILABILITY_FAILED = "periodUnavailability@EDIT_PERIOD_UNAVAILABILITY_FAILED"

export const DELETE_PERIOD_UNAVAILABILITY_INIT = "periodUnavailability@DEACTIVATE_PERIOD_UNAVAILABILITY_INIT"
export const DELETE_PERIOD_UNAVAILABILITY_SUCCEDED = "periodUnavailability@DEACTIVATE_PERIOD_UNAVAILABILITY_SUCCEDED"
export const DELETE_PERIOD_UNAVAILABILITY_FAILED = "periodUnavailability@DEACTIVATE_PERIOD_UNAVAILABILITY_FAILED"
