import _ from "lodash"

import { HTTP_METHODS } from "./../../../../../constants"
import { makeExternalCall } from "./../../../../../helpers"
import { isRLTLang } from "../../../../../i18n"


const FETCH_OPTIONS_ENDPOINT = "/api/pa/options/list?list_number=8"

const formatOptions = (options) => options.map((option) => (
  { label: isRLTLang() ? option.valueAr : option.valueFr, value: option.rank }))


export const eventThemeUIHelper = (callback, keyword) => {

  let endpoint = FETCH_OPTIONS_ENDPOINT
  let query = {}
  if (!_.isEmpty(keyword)) {
    query = { q: keyword }
  }

  return new Promise((resolve, reject) =>
    makeExternalCall(HTTP_METHODS.GET, endpoint, {}, {}, query)
      .then(resp => {
        let result = {}
        if (_.isPlainObject(resp.data)) {
          result = [resp.data]
        } else {
          result = resp.data
        }
        resolve(callback(formatOptions(result)))
      })
      .catch(err => reject(err.response))
  )
}



/* export const THEME = { 
  1: "EVENT.THEME.ACCESS_TO_EMPLOYMENT",
  2: "EVENT.THEME.TRAINING",
  3: "EVENT.THEME.PROJECT",
  4: "EVENT.THEME.HEALTH",
  5: "EVENT.THEME.CITIZENSHIP",
  6: "EVENT.THEME.CULTURE",
  7: "EVENT.THEME.HOBBIES",
  8: "EVENT.THEME.ADMINISTRATIVE",
  9: "EVENT.THEME.JUSTICE_LAW",
  10:"EVENT.THEME.LITERACY",
  11:"EVENT.THEME.FAMILY",
  12:"EVENT.THEME.FINANCE"
}


export const eventThemeUIHelper = (intl) =>
  Object.keys(THEME).map((key) => ({
    value: parseInt(key),
    label: intl.formatMessage({ id: THEME[key] }),
  })) */

