import _ from "lodash"

import { AR, FR, HTTP_METHODS } from "../../../../constants"
import { getAttr, makeCall } from "../../../../helpers"
import { store } from "../../../../configureStore"


const FETCH_SERVICES_ENDPOINT = "/api/integration-sessions/:param/folder-groups"

const formatSessionFolderGroups = (folderGroupSessions) => folderGroupSessions.map((folderGroup) => 
  ({ 
    label: getAttr(folderGroup, { [AR]: "nameAr", [FR]: "nameFr" }, "----") , 
    value: folderGroup.id 
  }) 
)


export const sessionFolderGroupsUIHelper = (callback, param) => {

  if (!_.isInteger(param) || !param){
    callback([])
    return
  }

  let endpoint = FETCH_SERVICES_ENDPOINT.replace(":param", param)
  const { token } = store.getState().common.auth || {}

  let query = {}
  return new Promise((resolve, reject) =>
    makeCall(HTTP_METHODS.GET, endpoint, {}, {'Authorization': `Bearer  ${token.access}`}, query)
    .then(resp => {
      let result = {}
      if (_.isPlainObject(resp.data)){
        result = [resp.data]
      }else {
        result = resp.data
      }
      resolve(callback(formatSessionFolderGroups( result )))
    })
    .catch(err => reject(err.response))
  )
}
