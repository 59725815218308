import _ from "lodash"

import { HTTP_METHODS } from "./../../../../../constants"
import { makeExternalCall } from "./../../../../../helpers"
import { isRLTLang } from "../../../../../i18n"


const FETCH_OPTIONS_ENDPOINT = "/api/pa/options/list?list_number=19"

const formatOptions = (options) => options.map((option) => (
  { label: isRLTLang() ? option.valueAr : option.valueFr, value: option.rank }))


export const familySituationsUIHelper = (callback, keyword) => {

  let endpoint = FETCH_OPTIONS_ENDPOINT
  let query = {}
  if (!_.isEmpty(keyword)) {
    query = { q: keyword }
  }

  return new Promise((resolve, reject) =>
    makeExternalCall(HTTP_METHODS.GET, endpoint, {}, {}, query)
      .then(resp => {
        let result = {}
        if (_.isPlainObject(resp.data)) {
          result = [resp.data]
        } else {
          result = resp.data
        }
        resolve(callback(formatOptions(result)))
      })
      .catch(err => reject(err.response))
  )
}


const uIHelper = (OBJECT) => (intl) =>
  Object.keys(OBJECT).map((value) => ({
    value: parseInt(value),
    label: intl.formatMessage({ id: OBJECT[value] }),
  }))
export const FAMILY_SITUATIONS = {
  1: "FOLDER.FAMILTY_INFORMATION.MOTHER_DEATH_INDICATION",
  2: "FOLDER.FAMILTY_INFORMATION.FATHER_DEATH_INDICATION",
  3: "FOLDER.FAMILTY_INFORMATION.SINGLE_MOTHER_INDICATION",
  4: "FOLDER.FAMILTY_INFORMATION.DIVORCE_INDICATION",
  5: "FOLDER.FAMILTY_INFORMATION.SEPARATION_INDICATION",
  6: "FOLDER.FAMILTY_INFORMATION.ABSENT_INDICATION",
  7: "FOLDER.FAMILTY_INFORMATION.MIGRATION_INDICATION",
  8: "FOLDER.FAMILTY_INFORMATION.PRISON_INDICATION",
  9: "FOLDER.FAMILTY_INFORMATION.DEATH_BOTH_PARENTS_INDICATION",
  10: "FOLDER.FAMILTY_INFORMATION.HELPLESSNESS_PARENTS_INDICATION",
  11: "FOLDER.FAMILTY_INFORMATION.FINAL_GIVE_UP_INDICATION"
}

/* export const familySituationsUIHelper = uIHelper(FAMILY_SITUATIONS)*/

export const FOLDER_INPUT_IS_REGENERATIVE_FORMULA = 1
export const FOLDER_INPUT_IS_EXTENDED_FAMILY = 2
export const FOLDER_INPUT_IS_FAMILY_RECEPTION = 3
export const TYPE_COMBINATION = {
  [FOLDER_INPUT_IS_REGENERATIVE_FORMULA]: "FOLDER.INPUT.IS_REGENERATIVE_FORMULA",
  [FOLDER_INPUT_IS_EXTENDED_FAMILY]: "FOLDER.INPUT.IS_EXTENDED_FAMILY",
  [FOLDER_INPUT_IS_FAMILY_RECEPTION]: "FOLDER.INPUT.IS_FAMILY_RECEPTION"
}

export const typeCombinationUIHelper = uIHelper(TYPE_COMBINATION)
export const HAS_ONE_PARENT_INDICATIONS = {
  1: "FOLDER.FAMILTY_INFORMATION.MOTHER_DEATH_INDICATION",
  2: "FOLDER.FAMILTY_INFORMATION.FATHER_DEATH_INDICATION",
  3: "FOLDER.FAMILTY_INFORMATION.SINGLE_MOTHER_INDICATION"
}

export const hasOneParentIndicationsUIHelper = uIHelper(HAS_ONE_PARENT_INDICATIONS)

export const DISJOINTED_INDICATIONS = {
  1: "FOLDER.FAMILTY_INFORMATION.DIVORCE_INDICATION",
  2: "FOLDER.FAMILTY_INFORMATION.SEPARATION_INDICATION",
  3: "FOLDER.FAMILTY_INFORMATION.ABSENT_INDICATION"
}

export const disjointedIndicationsUIHelper = uIHelper(DISJOINTED_INDICATIONS)

export const ONE_PARENT_ABSENCE_INDICATIONS = {
  1: "FOLDER.FAMILTY_INFORMATION.MIGRATION_INDICATION",
  2: "FOLDER.FAMILTY_INFORMATION.PRISON_INDICATION"
}

export const oneParentAbsenceIndicationsUIHelper = uIHelper(ONE_PARENT_ABSENCE_INDICATIONS)


export const WITHOUT_SUPPORT_INDICATIONS = {
  1: "FOLDER.FAMILTY_INFORMATION.DEATH_BOTH_PARENTS_INDICATION",
  2: "FOLDER.FAMILTY_INFORMATION.HELPLESSNESS_PARENTS_INDICATION",
  3: "FOLDER.FAMILTY_INFORMATION.FINAL_GIVE_UP_INDICATION"
}

export const withoutSupportTypeIndicationsUIHelper = uIHelper(WITHOUT_SUPPORT_INDICATIONS)


export const REGENERATIVE_FORMULA_INDICATIONS = {
  1: "FOLDER.FAMILTY_INFORMATION.MOTHER_REMARRIAGE_INDICATION",
  2: "FOLDER.FAMILTY_INFORMATION.FATHER_REMARRIAGE_INDICATION"
}

export const regenerativeFormulaTypeIndicationsUIHelper = uIHelper(REGENERATIVE_FORMULA_INDICATIONS)


export const EXTENDED_FAMILY_INDICATIONS = {
  1: "FOLDER.FAMILTY_INFORMATION.MOTHER_PARENTS_INDICATION",
  2: "FOLDER.FAMILTY_INFORMATION.FATHER_PARENTS_INDICATION"
}

export const extendedFamilyTypeIndicationsUIHelper = uIHelper(EXTENDED_FAMILY_INDICATIONS)


export const FAMILY_RECEPTION_INDICATIONS = {
  1: "FOLDER.FAMILTY_INFORMATION.WARRANTY_INDICATION",
  2: "FOLDER.FAMILTY_INFORMATION.ADOPTION_INDICATION",
  3: "FOLDER.FAMILTY_INFORMATION.FAMILY_DEPOSIT_INDICATION"
}

export const familyReceptionTypeIndicationsUIHelper = uIHelper(FAMILY_RECEPTION_INDICATIONS)
