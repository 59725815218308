import _ from "lodash"

import { HTTP_METHODS } from "./../../../../../constants"
import { makeExternalCall } from "./../../../../../helpers"
import { isRLTLang } from "../../../../../i18n"


const FETCH_OPTIONS_ENDPOINT = "/api/pa/options/list?list_number=2"

const formatOptions = (options) => options.map((option) => (
  { label: isRLTLang() ? option.valueAr : option.valueFr, value: option.rank }))


export const schoolLevelsUIHelper = (callback, keyword, param) => {

  let endpoint = FETCH_OPTIONS_ENDPOINT
  let query = {}
  if (!_.isEmpty(keyword)) {
    query = { q: keyword }
  }

  return new Promise((resolve, reject) =>
    makeExternalCall(HTTP_METHODS.GET, endpoint, {}, {}, query)
      .then(resp => {
        let result = {}
        if (_.isPlainObject(resp.data)) {
          result = [resp.data]
        } else {
          result = resp.data
        }
        resolve(callback(formatOptions(result)))
      })
      .catch(err => reject(err.response))
  )
}

const formatSchoolLevelOptions = (options) => {
  let values = {}
  options.forEach((option) => {
      values[option.rank] = isRLTLang() ? option.valueAr : option.valueFr
  })
  return values;
}


export const getSchoolLevelOptions = (callback, keyword) => {

let endpoint = FETCH_OPTIONS_ENDPOINT
let query = {}
if (!_.isEmpty(keyword)) {
  query = { q: keyword }
}

return new Promise((resolve, reject) =>
  makeExternalCall(HTTP_METHODS.GET, endpoint, {}, {}, query)
    .then(resp => {
      let result = {}
      if (_.isPlainObject(resp.data)) {
        result = [resp.data]
      } else {
        result = resp.data
      }
      resolve(callback(formatSchoolLevelOptions(result)))
    })
    .catch(err => reject(err.response))
)
  }

/* export const LEVEL_1_SCHOOL = 1
export const LEVEL_2_SCHOOL = 2
export const LEVEL_3_SCHOOL = 3
export const LEVEL_4_SCHOOL = 4
export const LEVEL_5_SCHOOL = 5
export const LEVEL_6_SCHOOL = 6
export const LEVEL_1_MIDDLE_SCHOOL = 7
export const LEVEL_2_MIDDLE_SCHOOL = 8
export const LEVEL_3_MIDDLE_SCHOOL = 9
export const LEVEL_1_SECONDARY = 10
export const LEVEL_2_SECONDARY = 11
export const LEVEL_3_SECONDARY = 12
export const LEVEL_4_SECONDARY = 13
export const NOT_EDUCATED = 14
export const LEVEL_1_CAP = 15
export const LEVEL_2_CAP = 16
export const LEVEL_1_BTP = 17
export const LEVEL_2_BTP = 18
export const SCHOOL_LEVELS = {
    [LEVEL_1_SCHOOL]: "SCHOOL_LEVELS.LEVEL_1_SCHOOL",
    [LEVEL_2_SCHOOL]: "SCHOOL_LEVELS.LEVEL_2_SCHOOL",
    [LEVEL_3_SCHOOL]: "SCHOOL_LEVELS.LEVEL_3_SCHOOL",
    [LEVEL_4_SCHOOL]: "SCHOOL_LEVELS.LEVEL_4_SCHOOL",
    [LEVEL_5_SCHOOL]: "SCHOOL_LEVELS.LEVEL_5_SCHOOL",
    [LEVEL_6_SCHOOL]: "SCHOOL_LEVELS.LEVEL_6_SCHOOL",
    [LEVEL_1_MIDDLE_SCHOOL]: "SCHOOL_LEVELS.LEVEL_1_MIDDLE_SCHOOL",
    [LEVEL_2_MIDDLE_SCHOOL]: "SCHOOL_LEVELS.LEVEL_2_MIDDLE_SCHOOL",
    [LEVEL_3_MIDDLE_SCHOOL]: "SCHOOL_LEVELS.LEVEL_3_MIDDLE_SCHOOL",
    [LEVEL_1_SECONDARY]: "SCHOOL_LEVELS.LEVEL_1_SECONDARY",
    [LEVEL_2_SECONDARY]: "SCHOOL_LEVELS.LEVEL_2_SECONDARY",
    [LEVEL_3_SECONDARY]: "SCHOOL_LEVELS.LEVEL_3_SECONDARY",
    [LEVEL_4_SECONDARY]: "SCHOOL_LEVELS.LEVEL_4_SECONDARY",
    [NOT_EDUCATED]: "SCHOOL_LEVELS.NOT_EDUCATED",
    [LEVEL_1_CAP]: "SCHOOL_LEVELS.LEVEL_1_CAP",
    [LEVEL_2_CAP]: "SCHOOL_LEVELS.LEVEL_2_CAP",
    [LEVEL_1_BTP]: "SCHOOL_LEVELS.LEVEL_1_BTP",
    [LEVEL_2_BTP]: "SCHOOL_LEVELS.LEVEL_2_BTP",
  }
  
export const schoolLevelsUIHelper = (intl) =>
    Object.keys(SCHOOL_LEVELS).map((key) => ({
      value: parseInt(key),
      label: intl.formatMessage({ id: SCHOOL_LEVELS[key] }),
    })) */

