export const INTEGRATION_SESSIONS = "/api/integration-sessions"
export const INTEGRATION_SESSION = "/api/integration-sessions/:param"

export const INTEGRATION_SESSION_APPOINTMENTS = "/api/integration-sessions/:param/appointments"
export const INTEGRATION_SESSION_APPOINTMENT = "/api/integration-sessions/:param/appointments/:appointmentParam"

export const INTEGRATION_SESSION_DEACTIVATE = "/api/integration-sessions/:param/deactivate"
export const INTEGRATION_SESSION_ACTIVATE = "/api/integration-sessions/:param/activate"


export const DOWNLOAD_EVENT = "/api/events/:param/print"

export const DOWNLOAD_SCHEDULE = "/api/integration-sessions/:param/schedules"
