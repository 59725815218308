import { ACTIONS } from './../constants'

const initialState = { 
  account: null, 
  isLoading: false, 
  isFetching: false, 
  appointment: null,
  appointments: [],
  appointmentFilterTerm: {},
  folder: {},
  success: {
    isCreated: false,
    isUpdated: false,
  },
  error: null 
}


export default (state = initialState, action) => {
  
  const { payload, type } = action
  
  switch (type) {

    case ACTIONS.CLEAR_STORE : {
      return {
        ...state,
        success: initialState.success,
        error: null,
        isFetching: false,
        isLoading: false,
      }
    }

    case ACTIONS.CLEAR_ERROR : {
      return { ...state, isLoading: false, isFetching: false, success: false, error: null }
    }

    case ACTIONS.FETCH_USER_INIT : {
      return { ...state, isFetching: true, success: false, error: null }
    }
    case ACTIONS.FETCH_USER_SUCCEDED : {
      return { ...state, isFetching: false, account: payload, error: null }
    }
    case ACTIONS.FETCH_USER_FAILED : {
      return { ...state, isFetching: false, success: false, error: payload }
    }
    
    case ACTIONS.CHANGE_PASSWORD_INIT : {
      return { ...state, isLoading: true, error: null, success:false }
    }
    case ACTIONS.CHANGE_PASSWORD_SUCCEDED : {
      return { ...state, isLoading: false, success: true, error: null }
    }
    case ACTIONS.CHANGE_PASSWORD_FAILED : {
      return { ...state, isLoading: false, success: false, error: payload }
    }

    case ACTIONS.UPDATE_ACCOUNT_INFORMATION_INIT : {
      return { ...state, isLoading: true, error: null }
    }
    case ACTIONS.UPDATE_ACCOUNT_INFORMATION_SUCCEDED : {
      return { ...state, isLoading: false, success: true, account: payload, error: null }
    }
    case ACTIONS.UPDATE_ACCOUNT_INFORMATION_FAILED : {
      return { ...state, isLoading: false, success: false, error: payload }
    }

    case ACTIONS.UPDATE_PERSONAL_INFORMATION_INIT : {
      return { ...state, isLoading: true, error: null }
    }
    case ACTIONS.UPDATE_PERSONAL_INFORMATION_SUCCEDED : {
      return { ...state, isLoading: false, success: true, account: payload, error: null }
    }
    case ACTIONS.UPDATE_PERSONAL_INFORMATION_FAILED : {
      return { ...state, isLoading: false, success: false, error: payload }
    }

    case ACTIONS.FETCH_APPOINTMENT_INIT: {
      return { ...state, isFetching: true, success: false, appointment: null, error: null }
    }
    case ACTIONS.FETCH_APPOINTMENT_SUCCEDED: {
      return { ...state, appointment: payload, isFetching: false, error: null }
    }
    case ACTIONS.FETCH_APPOINTMENT_FAILED: {
      return { ...state, isFetching: false, error: payload }
    }

    case ACTIONS.FETCH_APPOINTMENTS_INIT: {
      return { ...state, isFetching: true, appointments: [], error: null }
    }
    case ACTIONS.FETCH_APPOINTMENTS_SUCCEDED: {
      return { ...state, appointments: payload, isFetching: false, error: null }
    }
    case ACTIONS.FETCH_APPOINTMENTS_FAILED: {
      return { ...state, isFetching: false, error: payload }
    }

    case ACTIONS.FILTER_APPOINTMENT : {
      return { ...state, appointmentFilterTerm: { ...state.appointmentFilterTerm, ...payload } }
    }
   
    case ACTIONS.CREATE_APPOINTMENT_INIT: {
      return { ...state, isLoading: true, error: null, success: initialState.success }
    }
    case ACTIONS.CREATE_APPOINTMENT_SUCCEDED: {
      return {
        ...state,
        appointment: payload,
        appointments: [...(state.appointments || []), payload ],
        success: { ...state.success, isCreated: true },
        isLoading: false,
      }
    }
    case ACTIONS.CREATE_APPOINTMENT_FAILED: {
      return { ...state, isLoading: false, error: payload }
    }

    case ACTIONS.EDIT_APPOINTMENT_INIT: {
      return { ...state, isLoading: true, error: null, success: initialState.success }
    }
    case ACTIONS.EDIT_APPOINTMENT_SUCCEDED: {
      let appointments = []
      if (payload.isCanceled){
        appointments = state.appointments.filter((appointment) => appointment.id !== payload.id )
      }else {
        appointments = state.appointments.map((appointment) => appointment.id !== payload.id ? ({ ...appointment }) : ({ ...payload}) )
      }
      return { ...state, appointment: payload, success: { ...state.success, isUpdated: true }, appointments, isLoading: false, error: null }
    }
    case ACTIONS.EDIT_APPOINTMENT_FAILED: {
      return { ...state, isLoading: false, error: payload }
    }

    case ACTIONS.FETCH_FOLDER_INIT: {
      return { ...state, isFetching: true, refresh: false, error: null }
    }
    case ACTIONS.FETCH_FOLDER_SUCCEDED: {
      return { ...state, folder: payload, isLoading: false, isFetching: false, error: null }
    }
    case ACTIONS.FETCH_FOLDER_FAILED: {
      return { ...state, isFetching: false, isLoading: false, error: payload }
    }
    
    default: {
      return state
    }
  }
}
