import { ACTIONS } from "../constants"


const initialState = { 
  
  transferRequestsD2C: [], 
  totalSize: 0,
  isFetching: false, 
  isLoading: false,
  hasMore: true, 
  success: {
    isDeleted: false,
    isCreated: false,
    isAccepted: false,
    isRefused: false,
  },
  error: null
}


export default (state = initialState , action) => {
  const { payload, type } = action
  switch (type) {

    case ACTIONS.CLEAR_FOLDER : {
      return { ...state, success: initialState.success, error: null, isFetching: false, isLoading: false }
    }

    case ACTIONS.FETCH_TRANSFER_REQUESTS_D2C_INIT : {
      return { ...state, isFetching: true, error: null }
    }
    case ACTIONS.FETCH_TRANSFER_REQUESTS_D2C_SUCCEDED : {
      const { count, results } = payload
      return { ...state, totalSize: count, transferRequestsD2C: results, isFetching: false, error: null }
    }
    case ACTIONS.FETCH_TRANSFER_REQUESTS_D2C_FAILED : {
      return { ...state, isFetching: false, error: payload }
    }

 
    default: {
      return state
    }
  }
}
