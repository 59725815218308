// folder
export const CLEAR_FOLDER = "folder@CLEAR_FOLDER"
export const FILTER_TRANSFER_REQUEST = "folder@FILTER_TRANSFER_REQUEST"

export const FETCH_FOLDERS_INIT = "folder@FETCH_FOLDERS_INIT"
export const FETCH_FOLDERS_SUCCEDED = "folder@FETCH_FOLDERS_SUCCEDED"
export const FETCH_FOLDERS_FAILED = "folder@FETCH_FOLDERS_FAILED"

export const CREATE_FOLDER_INIT = "folder@CREATE_FOLDER_INIT"
export const CREATE_FOLDER_SUCCEDED = "folder@CREATE_FOLDER_SUCCEDED"
export const CREATE_FOLDER_FAILED = "folder@CREATE_FOLDER_FAILED"

export const FETCH_FOLDER_INIT = "folder@FETCH_FOLDER_INIT"
export const FETCH_FOLDER_SUCCEDED = "folder@FETCH_FOLDER_SUCCEDED"
export const FETCH_FOLDER_FAILED = "folder@FETCH_FOLDER_FAILED"

export const EDIT_FOLDER_INIT = "folder@EDIT_FOLDER_INIT"
export const EDIT_FOLDER_SUCCEDED = "folder@EDIT_FOLDER_SUCCEDED"
export const EDIT_FOLDER_FAILED = "folder@EDIT_FOLDER_FAILED"

export const DISABLE_FOLDER_INIT = "folder@DISABLE_FOLDER_INIT"
export const DISABLE_FOLDER_SUCCEDED = "folder@DISABLE_FOLDER_SUCCEDED"
export const DISABLE_FOLDER_FAILED = "folder@DISABLE_FOLDER_FAILED"

export const ENABLE_FOLDER_INIT = "folder@ENABLE_FOLDER_INIT"
export const ENABLE_FOLDER_SUCCEDED = "folder@ENABLE_FOLDER_SUCCEDED"
export const ENABLE_FOLDER_FAILED = "folder@ENABLE_FOLDER_FAILED"

export const CANCEL_TRANSFER_FOLDER_INIT = "folder@CANCEL_TRANSFER_FOLDER_INIT"
export const CANCEL_TRANSFER_FOLDER_SUCCEDED = "folder@CANCEL_TRANSFER_FOLDER_SUCCEDED"
export const CANCEL_TRANSFER_FOLDER_FAILED = "folder@CANCEL_TRANSFER_FOLDER_FAILED"

export const DISABLE_FOLDERS_INIT = "folder@DISABLE_FOLDERS_INIT"
export const DISABLE_FOLDERS_SUCCEDED = "folder@DISABLE_FOLDERS_SUCCEDED"
export const DISABLE_FOLDERS_FAILED = "folder@DISABLE_FOLDERS_FAILED"

export const ENABLE_FOLDERS_INIT = "folder@ENABLE_FOLDERS_INIT"
export const ENABLE_FOLDERS_SUCCEDED = "folder@ENABLE_FOLDERS_SUCCEDED"
export const ENABLE_FOLDERS_FAILED = "folder@ENABLE_FOLDERS_FAILED"

export const FETCH_FOLDER_CONTENT_INIT = "folder@FETCH_FOLDER_CONTENT_INIT"
export const FETCH_FOLDER_CONTENT_SUCCEDED = "folder@FETCH_FOLDER_CONTENT_SUCCEDED"
export const FETCH_FOLDER_CONTENT_FAILED = "folder@FETCH_FOLDER_CONTENT_FAILED"

export const EDIT_FOLDER_CONTENT_INIT = "folder@EDIT_FOLDER_CONTENT_INIT"
export const EDIT_FOLDER_CONTENT_SUCCEDED = "folder@EDIT_FOLDER_CONTENT_SUCCEDED"
export const EDIT_FOLDER_CONTENT_FAILED = "folder@EDIT_FOLDER_CONTENT_FAILED"

export const FETCH_TRANSFER_REQUEST_INIT = "folder@FETCH_TRANSFER_REQUEST_INIT"
export const FETCH_TRANSFER_REQUEST_SUCCEDED = "folder@FETCH_TRANSFER_REQUEST_SUCCEDED"
export const FETCH_TRANSFER_REQUEST_FAILED = "folder@FETCH_TRANSFER_REQUEST_FAILED"

export const FETCH_TRANSFER_REQUESTS_INIT = "folder@FETCH_TRANSFER_REQUESTS_INIT"
export const FETCH_TRANSFER_REQUESTS_SUCCEDED = "folder@FETCH_TRANSFER_REQUESTS_SUCCEDED"
export const FETCH_TRANSFER_REQUESTS_FAILED = "folder@FETCH_TRANSFER_REQUESTS_FAILED"


export const FETCH_TRANSFER_REQUESTS_D2C_INIT = "folder@FETCH_TRANSFER_REQUESTS_D2C_INIT"
export const FETCH_TRANSFER_REQUESTS_D2C_SUCCEDED = "folder@FETCH_TRANSFER_REQUESTS_D2C_SUCCEDED"
export const FETCH_TRANSFER_REQUESTS_D2C_FAILED = "folder@FETCH_TRANSFER_REQUESTS_D2C_FAILED"

export const CREATE_TRANSFER_REQUEST_INIT = "folder@CREATE_TRANSFER_REQUEST_INIT"
export const CREATE_TRANSFER_REQUEST_SUCCEDED = "folder@CREATE_TRANSFER_REQUEST_SUCCEDED"
export const CREATE_TRANSFER_REQUEST_FAILED = "folder@CREATE_TRANSFER_REQUEST_FAILED"

export const EDIT_TRANSFER_REQUEST_INIT = "folder@EDIT_TRANSFER_REQUEST_INIT"
export const EDIT_TRANSFER_REQUEST_SUCCEDED = "folder@EDIT_TRANSFER_REQUEST_SUCCEDED"
export const EDIT_TRANSFER_REQUEST_FAILED = "folder@EDIT_TRANSFER_REQUEST_FAILED"


export const FETCH_FOLDER_EXTRA_DATA_INIT = "folder@FETCH_FOLDER_EXTRA_DATA_INIT"
export const FETCH_FOLDER_EXTRA_DATA_SUCCEDED = "folder@FETCH_FOLDER_EXTRA_DATA_SUCCEDED"
export const FETCH_FOLDER_EXTRA_DATA_FAILED = "folder@FETCH_FOLDER_EXTRA_DATA_FAILED"

export const EDIT_FOLDER_EXTRA_DATA_INIT = "folder@EDIT_FOLDER_EXTRA_DATA_INIT"
export const EDIT_FOLDER_EXTRA_DATA_SUCCEDED = "folder@EDIT_FOLDER_EXTRA_DATA_SUCCEDED"
export const EDIT_FOLDER_EXTRA_DATA_FAILED = "folder@EDIT_FOLDER_EXTRA_DATA_FAILED"

export const CREATE_FOLDER_EXTRA_DATA_INIT = "folder@CREATE_FOLDER_EXTRA_DATA_INIT"
export const CREATE_FOLDER_EXTRA_DATA_SUCCEDED = "folder@CREATE_FOLDER_EXTRA_DATA_SUCCEDED"
export const CREATE_FOLDER_EXTRA_DATA_FAILED = "folder@CREATE_FOLDER_EXTRA_DATA_FAILED"

export const REFUSE_TRANSFER_REQUEST_INIT = "folder@REFUSE_TRANSFER_FOLDER_INIT"
export const REFUSE_TRANSFER_REQUEST_SUCCEDED = "folder@REFUSE_TRANSFER_FOLDER_SUCCEDED"
export const REFUSE_TRANSFER_REQUEST_FAILED = "folder@REFUSE_TRANSFER_FOLDER_FAILED"

export const ACCEPT_TRANSFER_REQUEST_INIT = "folder@ACCEPT_TRANSFER_FOLDER_INIT"
export const ACCEPT_TRANSFER_REQUEST_SUCCEDED = "folder@ACCEPT_TRANSFER_FOLDER_SUCCEDED"
export const ACCEPT_TRANSFER_REQUEST_FAILED = "folder@ACCEPT_TRANSFER_FOLDER_FAILED"

export const DELETE_TRANSFER_REQUEST_INIT = "DELETE_TRANSFER_REQUEST_INIT"
export const DELETE_TRANSFER_REQUEST_SUCCEDED = "DELETE_TRANSFER_REQUEST_SUCCEDED"
export const DELETE_TRANSFER_REQUEST_FAILED = "DELETE_TRANSFER_REQUEST_FAILED"

