export const CLEAR_ERROR = "profile@CLEAR_ERROR"
export const CLEAR_STORE = "profile@CLEAR_STORE"


export const UPDATE_ACCOUNT_INFORMATION_INIT = "profile@UPDATE_ACCOUNT_INFORMATION_INIT"
export const UPDATE_ACCOUNT_INFORMATION_SUCCEDED =  "profile@UPDATE_ACCOUNT_INFORMATION_SUCCEDED"
export const UPDATE_ACCOUNT_INFORMATION_FAILED = "profile@UPDATE_ACCOUNT_INFORMATION_FAILED"

export const UPDATE_PERSONAL_INFORMATION_INIT = "profile@UPDATE_PERSONAL_INFORMATION_INIT"
export const UPDATE_PERSONAL_INFORMATION_SUCCEDED = "profile@UPDATE_PERSONAL_INFORMATION_SUCCEDED"
export const UPDATE_PERSONAL_INFORMATION_FAILED = "profile@UPDATE_PERSONAL_INFORMATION_FAILED"

export const CHANGE_PASSWORD_INIT = "profile@CHANGE_PASSWORD_INIT"
export const CHANGE_PASSWORD_SUCCEDED = "profile@CHANGE_PASSWORD_SUCCEDED"
export const CHANGE_PASSWORD_FAILED = "profile@CHANGE_PASSWORD_FAILED"

export const FETCH_USER_INIT = "profile@FETCH_USER_INIT"
export const FETCH_USER_SUCCEDED = "profile@FETCH_USER_SUCCEDED"
export const FETCH_USER_FAILED = "profile@FETCH_USER_FAILED"

export const FETCH_APPOINTMENT_INIT = "profile@FETCH_APPOINTMENT_INIT"
export const FETCH_APPOINTMENT_SUCCEDED = "profile@FETCH_APPOINTMENT_SUCCEDED"
export const FETCH_APPOINTMENT_FAILED = "profile@FETCH_APPOINTMENT_FAILED"

export const FETCH_APPOINTMENTS_INIT = "profile@FETCH_APPOINTMENTS_INIT"
export const FETCH_APPOINTMENTS_SUCCEDED = "profile@FETCH_APPOINTMENTS_SUCCEDED"
export const FETCH_APPOINTMENTS_FAILED = "profile@FETCH_APPOINTMENTS_FAILED"

export const FILTER_APPOINTMENT = "profile@FILTER_APPOINTMENT"

export const CREATE_APPOINTMENT_INIT = "folder@CREATE_APPOINTMENT_INIT"
export const CREATE_APPOINTMENT_SUCCEDED = "folder@CREATE_APPOINTMENT_SUCCEDED"
export const CREATE_APPOINTMENT_FAILED = "folder@CREATE_APPOINTMENT_FAILED"

export const EDIT_APPOINTMENT_INIT = "folder@EDIT_APPOINTMENT_INIT"
export const EDIT_APPOINTMENT_SUCCEDED = "folder@EDIT_APPOINTMENT_SUCCEDED"
export const EDIT_APPOINTMENT_FAILED = "folder@EDIT_APPOINTMENT_FAILED"

export const FETCH_FOLDER_INIT = "folder@FETCH_FOLDER_INIT"
export const FETCH_FOLDER_SUCCEDED = "folder@FETCH_FOLDER_SUCCEDED"
export const FETCH_FOLDER_FAILED = "folder@FETCH_FOLDER_FAILED"