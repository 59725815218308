// userGroup
export const FETCH_USER_GROUPS_INIT = "userGroup@FETCH_USER_GROUPS_INIT"
export const FETCH_USER_GROUPS_SUCCEDED = "userGroup@FETCH_USER_GROUPS_SUCCEDED"
export const FETCH_USER_GROUPS_FAILED = "userGroup@FETCH_USER_GROUPS_FAILED"

export const CLEAR_USER_GROUP = "userGroup@CLEAR_USER_GROUP"

export const CREATE_USER_GROUP_INIT = "userGroup@CREATE_USER_GROUP_INIT"
export const CREATE_USER_GROUP_SUCCEDED = "userGroup@CREATE_USER_GROUP_SUCCEDED"
export const CREATE_USER_GROUP_FAILED = "userGroup@CREATE_USER_GROUP_FAILED"

export const FETCH_USER_GROUP_INIT = "userGroup@FETCH_USER_GROUP_INIT"
export const FETCH_USER_GROUP_SUCCEDED = "userGroup@FETCH_USER_GROUP_SUCCEDED"
export const FETCH_USER_GROUP_FAILED = "userGroup@FETCH_USER_GROUP_FAILED"

export const EDIT_USER_GROUP_INIT = "userGroup@EDIT_USER_GROUP_INIT"
export const EDIT_USER_GROUP_SUCCEDED = "userGroup@EDIT_USER_GROUP_SUCCEDED"
export const EDIT_USER_GROUP_FAILED = "userGroup@EDIT_USER_GROUP_FAILED"

export const DELETE_USER_GROUP_INIT = "userGroup@DELETE_USER_GROUP_INIT"
export const DELETE_USER_GROUP_SUCCEDED = "userGroup@DELETE_USER_GROUP_SUCCEDED"
export const DELETE_USER_GROUP_FAILED = "userGroup@DELETE_USER_GROUP_FAILED"
