// associateService
export const FETCH_ASSOCIATE_SERVICES_INIT = "associateService@FETCH_ASSOCIATE_SERVICES_INIT"
export const FETCH_ASSOCIATE_SERVICES_SUCCEDED = "associateService@FETCH_ASSOCIATE_SERVICES_SUCCEDED"
export const FETCH_ASSOCIATE_SERVICES_FAILED = "associateService@FETCH_ASSOCIATE_SERVICES_FAILED"

export const CLEAR_ASSOCIATE_SERVICE = "associateService@CLEAR_ASSOCIATE_SERVICE"

export const CREATE_ASSOCIATE_SERVICE_INIT = "associateService@CREATE_ASSOCIATE_SERVICE_INIT"
export const CREATE_ASSOCIATE_SERVICE_SUCCEDED = "associateService@CREATE_ASSOCIATE_SERVICE_SUCCEDED"
export const CREATE_ASSOCIATE_SERVICE_FAILED = "associateService@CREATE_ASSOCIATE_SERVICE_FAILED"

export const FETCH_ASSOCIATE_SERVICE_INIT = "associateService@FETCH_ASSOCIATE_SERVICE_INIT"
export const FETCH_ASSOCIATE_SERVICE_SUCCEDED = "associateService@FETCH_ASSOCIATE_SERVICE_SUCCEDED"
export const FETCH_ASSOCIATE_SERVICE_FAILED = "associateService@FETCH_ASSOCIATE_SERVICE_FAILED"

export const EDIT_ASSOCIATE_SERVICE_INIT = "associateService@EDIT_ASSOCIATE_SERVICE_INIT"
export const EDIT_ASSOCIATE_SERVICE_SUCCEDED = "associateService@EDIT_ASSOCIATE_SERVICE_SUCCEDED"
export const EDIT_ASSOCIATE_SERVICE_FAILED = "associateService@EDIT_ASSOCIATE_SERVICE_FAILED"

export const DELETE_ASSOCIATE_SERVICE_INIT = "associateService@DELETE_ASSOCIATE_SERVICE_INIT"
export const DELETE_ASSOCIATE_SERVICE_SUCCEDED = "associateService@DELETE_ASSOCIATE_SERVICE_SUCCEDED"
export const DELETE_ASSOCIATE_SERVICE_FAILED = "associateService@DELETE_ASSOCIATE_SERVICE_FAILED"

export const CHANGE_ASSOCIATE_SERVICE_STATUS_INIT = "associateService@CHANGE_ASSOCIATE_SERVICE_STATUS_INIT"
export const CHANGE_ASSOCIATE_SERVICE_STATUS_SUCCEDED = "associateService@CHANGE_ASSOCIATE_SERVICE_STATUS_SUCCEDED"
export const CHANGE_ASSOCIATE_SERVICE_STATUS_FAILED = "associateService@CHANGE_ASSOCIATE_SERVICE_STATUS_FAILED"
