// event
export const FETCH_EVENTS_INIT = "event@FETCH_EVENTS_INIT"
export const FETCH_EVENTS_SUCCEDED = "event@FETCH_EVENTS_SUCCEDED"
export const FETCH_EVENTS_FAILED = "event@FETCH_EVENTS_FAILED"

export const CLEAR_EVENT = "event@CLEAR_EVENT"

export const CREATE_EVENT_INIT = "event@CREATE_EVENT_INIT"
export const CREATE_EVENT_SUCCEDED = "event@CREATE_EVENT_SUCCEDED"
export const CREATE_EVENT_FAILED = "event@CREATE_EVENT_FAILED"

export const FETCH_EVENT_INIT = "event@FETCH_EVENT_INIT"
export const FETCH_EVENT_SUCCEDED = "event@FETCH_EVENT_SUCCEDED"
export const FETCH_EVENT_FAILED = "event@FETCH_EVENT_FAILED"

export const EDIT_EVENT_INIT = "event@EDIT_EVENT_INIT"
export const EDIT_EVENT_SUCCEDED = "event@EDIT_EVENT_SUCCEDED"
export const EDIT_EVENT_FAILED = "event@EDIT_EVENT_FAILED"

export const DELETE_EVENT_INIT = "event@DELETE_EVENT_INIT"
export const DELETE_EVENT_SUCCEDED = "event@DELETE_EVENT_SUCCEDED"
export const DELETE_EVENT_FAILED = "event@DELETE_EVENT_FAILED"

export const CLOSE_EVENT_INIT = "event@CLOSE_EVENT_INIT"
export const CLOSE_EVENT_SUCCEDED = "event@CLOSE_EVENT_SUCCEDED"
export const CLOSE_EVENT_FAILED = "event@CLOSE_EVENT_FAILED"