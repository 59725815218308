export const ASSESSMENT_LEVELS = "/api/assessment-levels"
export const ASSESSMENT_LEVEL = "/api/assessment-levels/:param"
export const ASSESSMENT_LEVEL_ACTIVATE = "/api/assessment-levels/:param/activate"
export const EXPORT_ASSESSMENT_LEVEL = "/api/assessment-levels/export"
export const IMPORT_ASSESSMENT_LEVEL = "/api/assessment-levels/import"
export const ASSESSMENT_LEVEL_CANCEL = "/api/request-assessment-levels/:param/cancel"


// request endpoints
export const REQUEST_ASSESSMENT_LEVELS = "/api/request-assessment-levels"
export const REQUEST_ASSESSMENT_LEVEL = "/api/request-assessment-levels/:param"
