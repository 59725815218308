import _ from "lodash"

import { HTTP_METHODS } from "../../../../constants"
import { makeCall } from "../../../../helpers"
import {isRLTLang} from "../../../../i18n"
import { store } from "../../../../configureStore"


const FETCH_FOLDER_INTEGRATION_SESSION = "/api/integration-sessions/:param/all"


const formatIntegrationSessions = (integrationSessions) => integrationSessions.map((integrationSession) =>
    ({
        label: isRLTLang() ? integrationSession.nameAr : integrationSession.nameFr ,
        value: integrationSession.id
    })
)


export const integrationSessionsByFolderUIHelper = (callback,params) => {
    let endpoint = FETCH_FOLDER_INTEGRATION_SESSION.replace(":param",params)
    const { token } = store.getState().common.auth || {}

    let query = {}

    return new Promise((resolve, reject) =>
        makeCall(HTTP_METHODS.GET, endpoint, {}, {'Authorization': `Bearer  ${token.access}`}, query)
            .then(resp => {
                let result = {}
                if (_.isPlainObject(resp.data)){
                    result = [resp.data]
                }else {
                    result = resp.data
                }
                resolve(callback(formatIntegrationSessions( result )))
            })
            .catch(err => reject(err.response))
    )
}
