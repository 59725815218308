import { HTTP_METHODS } from "./../../../../../constants"
import { makeCall } from "./../../../../../helpers"
import { store } from "./../../../../../configureStore"
import {isRLTLang} from "../../../../../i18n"

const FETCH_RULES_OF_PROCEDURES_ENDPOINT = "/api/partnerships/templates/all-rules-of-procedure"


const formatContracts = (contracts) => contracts.map((contract) => ({ 
  label: !isRLTLang() ? contract.nameFr : contract.nameAr, value: contract.id}))

export const rulesOfProcedureUIHelper = (callback=f=>f) => {

  const { token } = store.getState().common.auth || {}

  return new Promise((resolve, reject) =>
    makeCall(HTTP_METHODS.GET, FETCH_RULES_OF_PROCEDURES_ENDPOINT, {}, {'Authorization': `Bearer ${token.access}`}, {} )
    .then(resp => resolve(callback(formatContracts( resp.data ))))
    .catch(() => reject(callback([])))
  )
}
