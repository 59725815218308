export const GOVERNORATE = {
    1: "PARTNER.GOVERNORATE.ARIANA",
    2: "PARTNER.GOVERNORATE.BEN_AROUS",
    3: "PARTNER.GOVERNORATE.BIZERTE",
    4: "PARTNER.GOVERNORATE.BEJA",
    5: "PARTNER.GOVERNORATE.GAFSA",
    6: "PARTNER.GOVERNORATE.GABES",
    7: "PARTNER.GOVERNORATE.JENDOUBA",
    8: "PARTNER.GOVERNORATE.KAIRAWEN",
    9: "PARTNER.GOVERNORATE.KASSERINE",
    10: "PARTNER.GOVERNORATE.KEBELLI",
    11: "PARTNER.GOVERNORATE.KEF",
    12: "PARTNER.GOVERNORATE.MANOUBA",
    13: "PARTNER.GOVERNORATE.MEHDIA",
    14: "PARTNER.GOVERNORATE.MEDNINE",
    15: "PARTNER.GOVERNORATE.MONASTIR",
    16: "PARTNER.GOVERNORATE.NABEL",
    17: "PARTNER.GOVERNORATE.SELIANA",
    18: "PARTNER.GOVERNORATE.SFAX",
    19: "PARTNER.GOVERNORATE.SIDI_BOUZID",
    20: "PARTNER.GOVERNORATE.SOUSSE",
    21: "PARTNER.GOVERNORATE.TATAOUINE",
    22: "PARTNER.GOVERNORATE.TOZER",
    23: "PARTNER.GOVERNORATE.TUNIS",
    24: "PARTNER.GOVERNORATE.ZAGHWEN"
}
export const governorateUIHelper = (intl) =>
    Object.keys(GOVERNORATE).map((key) => ({
        value: parseInt(key),
        label: intl.formatMessage({ id: GOVERNORATE[key] }),
    }))
