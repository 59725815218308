import { ACTIONS } from "./../constants"


const initialState = {
  absenceSheetGroups: [],
  absenceSheetGroup: {},
  totalSize: 0,
  isFetching: false,
  isLoading: false,
  hasMore: true,
  success: {
    isDeactivated: false,
    isCreated: false,
    isUpdated: false,
    isActivated: false,
  },
  error: null
}

const combineAbsence = (absences) => {
  const absenceSheetMap = absences?.reduce((prevVal, val) => {
    if (!prevVal.hasOwnProperty(val.folder)){
      prevVal[val.folder] = val
    }
    prevVal[val.folder] = { ...prevVal[val.folder], [val.appointment]: val.status }
    return prevVal
  }, {})
  return Object.values(absenceSheetMap)
}

export default (state = initialState , action) => {
  const { payload, type } = action
  switch (type) {

    case ACTIONS.CLEAR_ABSENCE_SHEET : {
      return { ...state, success: initialState.success, error: null, isFetching: false, isLoading: false }
    }

    case ACTIONS.FETCH_ABSENCE_SHEETS_INIT : {
      return { ...state, isFetching: true, error: null }
    }
    case ACTIONS.FETCH_ABSENCE_SHEETS_SUCCEDED : {
      const { count, results } = payload
      return { ...state, totalSize: count, absenceSheetGroups: results, isFetching: false, error: null }
    }
    case ACTIONS.FETCH_ABSENCE_SHEETS_FAILED : {
      return { ...state, isFetching: false, error: payload }
    }

    case ACTIONS.CREATE_ABSENCE_SHEET_INIT : {
      return { ...state, isLoading: true, error: null, success: initialState.success }
    }
    case ACTIONS.CREATE_ABSENCE_SHEET_SUCCEDED : {
      return { ...state, success: { ...state.success, isCreated: true }, isLoading: false, error: null }
    }
    case ACTIONS.CREATE_ABSENCE_SHEET_FAILED : {
      return { ...state, error: payload, isLoading: false }
    }

    case ACTIONS.EDIT_ABSENCE_SHEET_INIT : {
      return { ...state, isLoading: true, error: null, success: initialState.success }
    }
    case ACTIONS.EDIT_ABSENCE_SHEET_SUCCEDED : {
      return { ...state, success: { ...state.success, isUpdated: true }, isLoading: false, error: null }

    }
    case ACTIONS.EDIT_ABSENCE_SHEET_FAILED : {
      return { ...state, error: payload, isLoading: false }
    }

    case ACTIONS.FETCH_ABSENCE_SHEET_INIT : {
      return { ...state, isLoading: true, absenceSheetGroup: null, error: null }
    }
    case ACTIONS.FETCH_ABSENCE_SHEET_SUCCEDED : {
      return { ...state, absenceSheetGroup: { ...payload, absenceSheetMap: combineAbsence(payload?.newAbsences) }, isLoading: false, error: null }
    }
    case ACTIONS.FETCH_ABSENCE_SHEET_FAILED : {
      return { ...state, isLoading: false, error: payload }
    }

    case ACTIONS.ACTIVATE_ABSENCE_SHEET_INIT : {
      return { ...state, isLoading: true, success: initialState.success, error: null }
    }
    case ACTIONS.ACTIVATE_ABSENCE_SHEET_SUCCEDED : {
      return { ...state, success: { ...state.success, isActivated: true }, isLoading: false, error: null }
    }
    case ACTIONS.ACTIVATE_ABSENCE_SHEET_FAILED : {
      return { ...state, isLoading: false, error: payload }
    }

    case ACTIONS.DEACTIVATE_ABSENCE_SHEET_INIT: {
      return { ...state, isLoading: true, success: initialState.success, error: null }
    }
    case ACTIONS.DEACTIVATE_ABSENCE_SHEET_SUCCEDED: {
      return { ...state, success: { ...state.success, isDeactivated: true }, isLoading: false, error: null }
    }
    case ACTIONS.DEACTIVATE_ABSENCE_SHEET_FAILED: {
      return { ...state, isLoading: false, error: payload }
    }

    default: {
      return state
    }
  }
}
